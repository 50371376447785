attribute vec3 offset;
attribute vec2 planeIndex;

uniform float uTime;
uniform float uWavesElevation;
uniform vec2 uWavesFrequency;
uniform vec3 uDepthColor;
uniform vec3 uSurfaceColor;
uniform float uColorOffset;

varying vec2 vUv;
varying vec3 vPosition;
varying vec3 vWaterColor;
varying vec2 vPlaneIndex;
varying vec3 vPlanePosition;

void main()
{
    vec3 positionModified = position;

    // Wellenberechnung basierend auf offset
    float waveFrequency = uWavesFrequency.x;
    float wavePhase = uTime * 1.2 + (offset.x * 0.5 + offset.z) * waveFrequency;
    float wave = sin(wavePhase) * uWavesElevation + uWavesElevation + 0.005;
    positionModified.y += wave;

    // Farben berechnen
    float mixStrength = (uWavesElevation + (uColorOffset - (0.5 * uWavesElevation)));
    vWaterColor = mix(uDepthColor, uSurfaceColor, mixStrength);

    // Varyings
    vUv = uv;
    vPosition = positionModified + offset;
    vPlaneIndex = planeIndex;
    vPlanePosition = offset;

    gl_Position = projectionMatrix * modelViewMatrix * vec4(positionModified + offset, 1.0);
}