varying vec2 vUv;
uniform float uTime;
uniform sampler2D uCloudTexture;
uniform float uCloudAlpha;
uniform float uAspectRatio;
uniform float uCloudMove;

void main() {
    vec2 normalizedUv = vUv - 0.5;
    normalizedUv.x *= uAspectRatio;

    // Basis UV-Koordinaten mit Bewegung
    vec2 cloudUv = normalizedUv * 0.67 + vec2(- uTime * 0.012, -uTime * 0.022);
    
    // Anpassung für das Seitenverhältnis der Textur
    cloudUv.x /= 1.691;
    
    // Verschiebung der Startposition
    cloudUv.x -= 0.18 / uAspectRatio + (uCloudMove * 0.2);
    cloudUv.y -= 0.45 + (uCloudMove * 0.1);
    
    // Fraktioniere die UV-Koordinaten für Wiederholung
    vec2 cloudUvFract = fract(cloudUv);
    
    // Sample die Textur
    vec4 cloudColor = texture2D(uCloudTexture, cloudUvFract);
    
    // Anwenden des smoothstep für weiche Grenzen
    vec2 smoothValue = smoothstep(0.0, 0.1, cloudUvFract) * smoothstep(0.0, 0.1, 1.0 - cloudUvFract);
    float smoothFactor = smoothValue.x * smoothValue.y;
    
    // Berechne den Alpha-Wert mit dem Glättungsfaktor
    float alpha = cloudColor.r * uCloudAlpha * smoothFactor;
    
    // Setze die finale Farbe
    gl_FragColor = vec4(0.569,0.447,0.314, alpha);
    
    #include <tonemapping_fragment>
    #include <colorspace_fragment>
}