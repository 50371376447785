// language-manager.js
export class LanguageManager {
  constructor(scene, planeMaterial) {
    this.currentLanguage = 'de';
    this.translations = {};
    this.scene = scene; // Übergabe der Szene
    this.planeMaterial = planeMaterial; // Übergabe des Materials
  }

  async loadTranslations(language) {
    if (!this.translations[language]) {
      try {
        const response = await fetch(`./translations/${language}.json`);
        if (!response.ok) {
          throw new Error(`Fehler beim Laden der Übersetzungen für Sprache: ${language}`);
        }
        this.translations[language] = await response.json();
      } catch (error) {
        console.error(error);
      }
    }
  }

  async setLanguage(language) {
    await this.loadTranslations(language);
    this.currentLanguage = language;
    this.updateTexts();
  }

  getText(key) {
    if (
      this.translations[this.currentLanguage] &&
      this.translations[this.currentLanguage][key]
    ) {
      return this.translations[this.currentLanguage][key];
    } else {
      console.warn(
        `Übersetzung für Schlüssel "${key}" nicht gefunden in Sprache "${this.currentLanguage}"`
      );
      return key;
    }
  }

  updateTexts() {
    // Aktualisieren von three.js Texten
    if (this.scene) {
      this.scene.traverse((object) => {
        if (object.isText) {
          const newText = this.getText(object.name);
          if (newText && object.text !== newText) {
            object.text = newText;
            object.needsUpdate = true;
          }
        }
      });
    }

    // Aktualisieren von HTML-Texten
    document.querySelectorAll('[data-i18n]').forEach((element) => {
      const key = element.getAttribute('data-i18n');
      const translation = this.getText(key);
      if (element.tagName.toLowerCase() === 'input' || element.tagName.toLowerCase() === 'textarea') {
        element.placeholder = translation;
      } else {
        element.innerHTML = translation;
      }
    });

    // Aktualisieren des Dokumenttitels
    const titleElements = document.getElementsByTagName('title');
    if (titleElements.length > 0) {
      const titleKey = titleElements[0].getAttribute('data-i18n');
      if (titleKey) {
        titleElements[0].innerHTML = this.getText(titleKey);
      }
    }
  }

}
