uniform sampler2D uMap;
uniform vec4 uTint;
uniform float uRadialScale;
uniform float uLengthScale;
uniform float uTime;
uniform float uSpeed;
uniform float uIntensity;


varying vec2 vUv;
varying vec3 vPos;
varying float vAngleFade;

void main()
{
    // Polar coordinate texture sampling
    vec2 center = vec2(0.5, 0.5);
    vec2 delta = vUv - center;
    float radius = length(delta) * 2.0 * uRadialScale;
    float angle = atan(delta.x, delta.y) * 1.0 / 6.28 * uLengthScale;
    
    vec2 polarUv = vec2(radius, angle);
    
    // Continuous movement with sine
    float movement = sin(uTime * uSpeed) * 0.5 + 0.5;
    vec2 uv1 = polarUv + vec2(movement, -uTime * 0.008);
    vec2 uv2 = polarUv + vec2(movement + 0.5, -uTime * 0.008);
    
    // Sample texture at two positions
    vec4 noise1 = texture2D(uMap, fract(uv1));
    vec4 noise2 = texture2D(uMap, fract(uv2));
    
    // Blend between the two samples
    float blendFactor = smoothstep(0.2, 1.0, fract(uTime * uSpeed * 0.5));
    float radialAlpha = mix(
        dot(noise1.xyz, vec3(0.3, 0.59, 0.11)),
        dot(noise2.xyz, vec3(0.3, 0.59, 0.11)),
        blendFactor
    );
    
    // Vertical fade for smoother edges
    float verticalFade = smoothstep(1.0, 0.0, vPos.z - 6.0);
        
    // Final alpha calculation with edge fade
    float alpha = radialAlpha * verticalFade * vAngleFade * uIntensity;
    
    gl_FragColor = vec4(uTint.xyz, alpha);
}
