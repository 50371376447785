// Vertex Shader
varying vec2 vUv;
varying vec3 vPos;
uniform vec3 uCameraPosition;
varying float vAngleFade;


void main()
{
	vUv = uv; 
	vPos = position;
	
	gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);

    vec3 toCamera = normalize(uCameraPosition - vPos);
    float angleFade = dot(normalize(vPos), toCamera);
    vAngleFade = smoothstep(0.0, 0.25, angleFade);
}