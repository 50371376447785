varying vec2 vUv;
uniform vec2 uScale;
uniform float uSunAlpha;

void main() {
    vec2 centeredUv = (vUv - 0.5) / uScale + 0.5;
    float distance = length(centeredUv - 0.5);
    
    // Innerer Kreis (Sonnenscheibe)
    float innerCircle = smoothstep(0.22, 0.21, distance);
    
    // Äußerer Schein
    float outerGlow = smoothstep(0.5, 0.2, distance);
    
    // Kombiniere inneren Kreis und äußeren Schein
    float finalAlpha = max(innerCircle, outerGlow * 0.6);
    
    // Sonnenfarbe (leicht gelblich)
    vec3 sunColor = vec3(1.0, 0.98, 0.9);
    
    // Finaler Farbwert mit Transparenz
    gl_FragColor = vec4(sunColor, finalAlpha  * uSunAlpha);
}