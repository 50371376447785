uniform float uTime;
uniform sampler2D uWaterNoiseTexture;
uniform sampler2D uLineTexture;
uniform vec3 uLineColor;
uniform float uLineVisibility;
uniform sampler2D uBorderTexture;
uniform float uBorderSize;
uniform float uBorderVisibility;
uniform sampler2D uPortTexture; 
uniform float uPortVisibility;
uniform float uAlpha;
uniform vec2 uShipMovement;

varying vec2 vUv;
varying vec3 vWaterColor;
varying vec2 vPlaneIndex;

void main()
{
    // UV-Berechnung für die Wassertextur
    vec2 tileUv = vUv * 0.005;
    vec2 waterUv = (vPlaneIndex * 0.005) + tileUv;
    waterUv -= uTime * 0.013;
    
    vec4 waterNoiseColor = texture2D(uWaterNoiseTexture, waterUv);
        
    // Mischen aller Farben
    vec3 finalColor = vWaterColor;

    // Separate UV-Berechnung für die Linien-Textur
    vec2 lineUv = (vPlaneIndex + vUv) / 64.0;
    lineUv -= uTime * 0.050;
    
    // Zeitverzögerte Bewegung
    vec2 delayedMovement = mix(vec2(0.0), uShipMovement * 1.3, clamp(uTime / 2.0, 0.0, 1.0));
    
    // Diagonale Bewegung basierend auf der verzögerten Schiffbewegung
    lineUv -= delayedMovement * 0.1;

    vec4 lineColor = texture2D(uLineTexture, lineUv);
    finalColor = mix(finalColor, mix(finalColor, uLineColor, lineColor.r), uLineVisibility);

    vec2 borderUv = (vPlaneIndex + vUv) / uBorderSize;
    vec4 borderColor = texture2D(uBorderTexture, borderUv);
    finalColor = mix(finalColor, mix(finalColor, uLineColor, borderColor.r), uBorderVisibility);

    vec2 portUv = (vPlaneIndex + vUv) / 32.0;
    vec4 portColor = texture2D(uPortTexture, portUv);
    finalColor = mix(finalColor, mix(finalColor, uLineColor, portColor.r), uPortVisibility);

    float alpha = uAlpha * waterNoiseColor.r;

    gl_FragColor = vec4(finalColor, alpha);

    #include <tonemapping_fragment>
    #include <colorspace_fragment>
}