import * as THREE from 'three'
import { Gradient } from "whatamesh";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { makeItGrain } from './shaders/grain-effect/GrainShader.js'
import { getGPUTier } from 'detect-gpu'
import gsap from 'gsap'
import waterVertexShader from './shaders/water/water-vertex.glsl'
import waterFragmentShader from './shaders/water/water-fragment.glsl'
import sunVertexShader from './shaders/sun/sun-vertex.glsl'
import sunFragmentShader from './shaders/sun/sun-fragment.glsl'
import shineVertexShader from './shaders/shine/shine-vertex.glsl'
import shineFragmentShader from './shaders/shine/shine-fragment.glsl'
import cloudVertexShader from './shaders/cloud/cloud-vertex.glsl'
import cloudFragmentShader from './shaders/cloud/cloud-fragment.glsl'
import godraysVertexShader from './shaders/godrays/godraysVertex.glsl'
import godraysFragmentShader from './shaders/godrays/godraysFragment.glsl'
import MouseFollower from "mouse-follower"
import { LanguageManager } from './language-manager.js';

// Languages
const languageManager = new LanguageManager();

async function init() {
  // Initialisierung des LanguageManagers
  await initLanguageManager();
}

// Eventlistener Language-Buttons
document.querySelector('.language-switcher').addEventListener('click', (event) => {
    const target = event.target.closest('a');
    if (target && target.dataset.lang) {
        event.preventDefault(); // Verhindert das Standardverhalten des Links
        const selectedLanguage = target.dataset.lang;
        languageManager.setLanguage(selectedLanguage).then(() => {
            updateLanguageSwitcher(selectedLanguage); // Markiert den aktiven Button

            // Aktualisieren der Uniforms direkt hier
            updateUniformsForLanguage(selectedLanguage);
        });
    }
});

// Funktion zum Aktualisieren der Uniforms basierend auf der ausgewählten Sprache
function updateUniformsForLanguage(lang) {
    if (planeMaterial && planeMaterial.uniforms) {
        // Aktualisieren der uLineTexture
        if (planeMaterial.uniforms.uLineTexture) {
            planeMaterial.uniforms.uLineTexture.value = lineTextures[lang];
            planeMaterial.uniforms.uLineTexture.value.needsUpdate = true;
        }

        // Aktualisieren der uBorderTexture
        if (planeMaterial.uniforms.uBorderTexture) {
            planeMaterial.uniforms.uBorderTexture.value = borderTextures[lang];
            planeMaterial.uniforms.uBorderTexture.value.needsUpdate = true;
        }
    } else {
        console.warn('planeMaterial oder seine uniforms sind nicht definiert.');
    }
}

// Aktiven Language-Button hervorheben
function updateLanguageSwitcher(selectedLanguage) {
const languageLinks = document.querySelectorAll('.language-switcher .language-link');
languageLinks.forEach(link => {
    if (link.dataset.lang === selectedLanguage) {
    link.classList.add('active');
    } else {
    link.classList.remove('active');
    }
});
}

// Sprachanpassung
async function initLanguageManager() {
await languageManager.loadTranslations('de');
await languageManager.loadTranslations('en');
await languageManager.loadTranslations('fr');
await languageManager.setLanguage('de'); // Setzen Sie die Standardsprache
updateLanguageSwitcher('de'); // Markieren Sie die Standardsprache als aktiv
}

init();

// Mobile?
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

// Event-Listener für Geräteorientierung hinzufügen, wenn auf Mobilgeräten
if (isMobile) {
    window.addEventListener('deviceorientation', (event) => {
        deviceOrientation.alpha = event.alpha;
        deviceOrientation.beta = event.beta;
        deviceOrientation.gamma = event.gamma;
    }, true);
}

/**
 * Device Type Detection Function
 */
function getDeviceType() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const isPortrait = height > width;

    if (width <= 767) {
        return 'mobile';
    } else if (isPortrait && width <= 1025) {
        return 'tablet';
    } else if (!isPortrait && width >= 1280) {
        return 'desktop';
    } else {
        return 'tablet';
    }
}

function isLandscape() {
    if (window.screen.orientation && window.screen.orientation.type) {
        return window.screen.orientation.type.startsWith('landscape');
    } else if (window.orientation !== undefined) {
        return Math.abs(window.orientation) === 90;
    }
    // Fallback für Browser, die keine der obigen APIs unterstützen
    return window.innerWidth > window.innerHeight;
}

function updateVisibility() {
    const rotatePhoneElement = document.querySelector('.rotate-phone');
    if (!rotatePhoneElement) return; // Überprüfe, ob das Element existiert

    if (isLandscape() && (isMobile || getDeviceType() === 'mobile')) {
        rotatePhoneElement.classList.add('visible');
    } else {
        rotatePhoneElement.classList.remove('visible');
    }
}

// Initialer Aufruf beim Laden der Seite
updateVisibility();

// Event Listener für Orientierungsänderungen
window.addEventListener('orientationchange', updateVisibility);


/* Cursor */    

    MouseFollower.registerGSAP(gsap);

    const cursor = new MouseFollower({
        container: document.body,
        speed: 0.3,
        skewing: 0,
        skewingText: 0,
    });

if (!isMobile) {
    cursor.show();
}


/**
 * Base
 */

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Gradient
const gradient = new Gradient();
gradient.initGradient("#gradient-canvas");

// Scene
const scene = new THREE.Scene()

/**
 * Sizes
 */

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

// Funktion zum Setzen der CSS-Variable
function setVh() {
    let vh = sizes.height;
    document.documentElement.style.setProperty('--seizesheight', `${vh}px`);
}

// Detect GPU performance
let targetPixelRatio = 3;

getGPUTier()
  .then((tier) => {

    if (tier && typeof tier.fps === 'number') {
      if (tier.fps <= 60) {
        targetPixelRatio = 2;
      } else {
        targetPixelRatio = 3;
      }
    } else {

    }

    renderer.setPixelRatio(Math.min(window.devicePixelRatio, targetPixelRatio));
  })
  .catch((error) => {
    console.error("Fehler beim Abrufen der GPU-Tier:", error);
    // Im Fehlerfall den Standardwert verwenden
    targetPixelRatio = 3;
    renderer.setPixelRatio(targetPixelRatio);
  });

setVh();

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, targetPixelRatio));

    // Update Uniform cloudPlane
    cloudPlaneMaterial.uniforms.uAspectRatio.value = camera.aspect;

    // Update camera positions if necessary
    updateCameraPosition(currentSection);

    // Setze die CSS-Variable
    setVh();

    //Landscape?
    updateVisibility;
});

/*
Audio 
*/

class OptimizedAudioManager {
    constructor(camera) {
        this.listener = new THREE.AudioListener();
        this.audioLoader = new THREE.AudioLoader();
        camera.add(this.listener);
        this.backgroundTracks = {};
        this.songs = {};
        this.effectSounds = {};
        this.activeEffects = new Set();
        this.currentBackgroundTrack = null;
        this.fadingTrack = null;
        this.fadeStartTime = 0;
        this.fadeDuration = 0;
        this.isFading = false;
        this.masterVolume = 1;
        this.isMuted = false;
    }

    // Methode zum Setzen des targetVolume und Anwenden des masterVolume
    setTargetVolume(sound, volume) {
        const clampedVolume = Math.max(0, Math.min(1, volume)); // Sicherstellen, dass der Wert zwischen 0 und 1 liegt
        if (!isFinite(clampedVolume)) {
            console.warn(`Attempted to set non-finite volume: ${volume}. Using 0.`);
            sound.userData.targetVolume = 0;
        } else {
            sound.userData.targetVolume = clampedVolume;
        }
        sound.setVolume(sound.userData.targetVolume * this.getEffectiveVolume());
    }

    loadBackgroundTrack(sectionName, url) {
        return new Promise((resolve, reject) => {
            this.audioLoader.load(url, (buffer) => {
                const sound = new THREE.Audio(this.listener);
                sound.setBuffer(buffer);
                sound.setLoop(true);
                sound.name = sectionName;
                sound.userData = { targetVolume: 0 }; // Initiales targetVolume auf 0 setzen
                sound.setVolume(sound.userData.targetVolume * this.getEffectiveVolume()); // Angepasst
                this.backgroundTracks[sectionName] = sound;
                resolve(sound);
            }, undefined, reject);
        });
    }

    loadSongTrack(songName, url) {
        return new Promise((resolve, reject) => {
            this.audioLoader.load(
                url,
                (buffer) => {
                    const sound = new THREE.Audio(this.listener);
                    sound.setBuffer(buffer);
                    sound.setLoop(true);
                    sound.name = songName;
                    sound.isFirstPlay = true;
                    sound.userData = { targetVolume: 0 };
                    sound.setVolume(sound.userData.targetVolume * this.getEffectiveVolume());
                    this.songs[songName] = sound;
                    resolve(sound);
                },
                () => {}, // Empty onProgress callback to correctly align parameters
                (error) => {
                    console.error(`Error loading song ${songName}:`, error);
                    reject(error);
                }
            );
        });
    }

    loadEffectSound(name, url) {
        return new Promise((resolve, reject) => {
            this.audioLoader.load(url, (buffer) => {
                const sound = new THREE.Audio(this.listener);
                sound.setBuffer(buffer);
                sound.userData = { targetVolume: 1 }; // Standard targetVolume auf 1 setzen
                sound.setVolume(sound.userData.targetVolume * this.getEffectiveVolume()); // Angepasst
                this.effectSounds[name] = sound;
                resolve(sound);
            }, undefined, reject);
        });
    }

    playBackgroundForSection(sectionName, fadeDuration = 1, targetVolume = 1) {
        const newTrack = this.backgroundTracks[sectionName];
        if (!newTrack) {
            console.warn(`No track found for section: ${sectionName}`);
            return;
        }

        // Wenn der neue Track bereits aktuell ist, nur das Volume anpassen
        if (this.currentBackgroundTrack === newTrack) {
            if (newTrack.userData.targetVolume !== targetVolume) {
                this.setTargetVolume(newTrack, targetVolume);
                this.fadeStartTime = performance.now();
                this.fadeDuration = fadeDuration;
                this.isFading = true;
                this.update();
            }
            return;
        }

        // Wenn ein anderer Hintergrundtrack spielt, setze ihn als fadingTrack
        if (this.currentBackgroundTrack && this.currentBackgroundTrack !== newTrack) {
            this.fadingTrack = this.currentBackgroundTrack;
        }

        // Starte den neuen Track, wenn er nicht bereits spielt
        if (!newTrack.isPlaying) {
            newTrack.play();
            newTrack.setVolume(0); // Start bei Lautstärke 0
        }

        // Setze den neuen Track als aktuellen Hintergrundtrack
        this.currentBackgroundTrack = newTrack;
        // Setze das Zielvolumen für den neuen Track
        this.setTargetVolume(newTrack, targetVolume);

        // Setze Fading-Parameter
        this.fadeStartTime = performance.now();
        this.fadeDuration = fadeDuration;
        this.isFading = true;

        // Starte die Update-Schleife für das Fading
        this.update();
    }

    playSongForSection(songName, fadeDuration = 0, targetVolume = 0, firstPlayOffset = 0) {
        const song = this.songs[songName];
        if (!song) {
            console.warn(`No song found with name: ${songName}`);
            return;
        }
    
        if (!song.isPlaying) {
            if (song.isFirstPlay) {
                song.offset = firstPlayOffset;
                song.isFirstPlay = false;
            } else {
                song.offset = 0;
            }
            song.play();
        }
    
        this.fadeSongVolume(song, fadeDuration, targetVolume);
    }

    fadeSongVolume(song, duration, targetVolume) {
        const startVolume = song.userData.targetVolume;
        const startTime = performance.now();
    
        const fade = () => {
            const now = performance.now();
            const elapsed = (now - startTime) / 1000; // Sekunden
            const t = Math.min(elapsed / duration, 1);
    
            const currentVolume = startVolume + (targetVolume - startVolume) * t;
            const clampedVolume = Math.max(0, Math.min(1, currentVolume));
    
            if (!isFinite(clampedVolume)) {
                console.warn(`Attempted to set non-finite volume: ${currentVolume}. Using 0.`);
                song.setVolume(0);
            } else {
                song.setVolume(clampedVolume * this.getEffectiveVolume());
            }
    
            if (t < 1) {
                requestAnimationFrame(fade);
            } else {
                // Stelle sicher, dass das endgültige Zielvolumen gesetzt ist
                this.setTargetVolume(song, targetVolume);
            }
        };
    
        if (duration > 0) {
            fade();
        } else {
            // Wenn keine Fade-Dauer, setze die Lautstärke direkt
            this.setTargetVolume(song, targetVolume);
        }
    }

    update() {
        if (this.isFading) {
            const currentTime = performance.now();
            const elapsedTime = (currentTime - this.fadeStartTime) / 1000; // Sekunden
            const t = Math.min(elapsedTime / this.fadeDuration, 1);

            // Fade out des alten Hintergrundtracks
            if (this.fadingTrack) {
                const fadingTargetVolume = 0; // Ziel ist, ihn auf 0 zu setzen
                const fadingStartVolume = this.fadingTrack.userData.targetVolume;
                const fadingVolume = fadingStartVolume * (1 - t);
                const clampedFadingVolume = Math.max(0, Math.min(1, fadingVolume));
                if (!isFinite(clampedFadingVolume)) {
                    console.warn(`Attempted to set non-finite fading volume: ${fadingVolume}. Using 0.`);
                    this.fadingTrack.setVolume(0);
                } else {
                    this.fadingTrack.setVolume(clampedFadingVolume * this.getEffectiveVolume());
                }
                if (t === 1) {
                    this.fadingTrack.stop();
                    this.fadingTrack = null;
                }
            }

            // Fade in des neuen Hintergrundtracks
            if (this.currentBackgroundTrack) {
                const targetVolume = this.currentBackgroundTrack.userData.targetVolume;
                const newVolume = targetVolume * t;
                const clampedNewVolume = Math.max(0, Math.min(1, newVolume));
                if (!isFinite(clampedNewVolume)) {
                    console.warn(`Attempted to set non-finite new volume: ${newVolume}. Using 0.`);
                    this.currentBackgroundTrack.setVolume(0);
                } else {
                    this.currentBackgroundTrack.setVolume(clampedNewVolume * this.getEffectiveVolume());
                }
            }

            if (t < 1) {
                requestAnimationFrame(() => this.update());
            } else {
                this.isFading = false;
            }
        }
    }

    adjustBackgroundVolume(duration = 2.5, newVolume) {
        if (!this.currentBackgroundTrack) {
            console.warn('No background track is currently playing.');
            return;
        }

        const startVolume = this.currentBackgroundTrack.userData.targetVolume;
        const startTime = performance.now();

        const adjustVolume = () => {
            const elapsedTime = (performance.now() - startTime) / 1000; // Sekunden
            const progress = Math.min(elapsedTime / duration, 1);

            // Easing-Funktion für glattere Übergänge (anpassbar)
            const easedProgress = 1 - Math.pow(1 - progress, 3); // Kubische Ease-Out

            const currentTargetVolume = startVolume + (newVolume - startVolume) * easedProgress;
            const clampedVolume = Math.max(0, Math.min(1, currentTargetVolume));

            if (!isFinite(clampedVolume)) {
                console.warn(`Attempted to set non-finite volume: ${currentTargetVolume}. Using 0.`);
                this.currentBackgroundTrack.setVolume(0);
            } else {
                this.currentBackgroundTrack.setVolume(clampedVolume * this.getEffectiveVolume());
            }

            if (progress < 1) {
                requestAnimationFrame(adjustVolume);
            } else {
                // Stelle sicher, dass das endgültige Zielvolumen gesetzt ist
                this.setTargetVolume(this.currentBackgroundTrack, newVolume);
            }
        };

        adjustVolume();
    }

    adjustSongVolume(songName, duration = 2.5, targetVolume) {
        const song = this.songs[songName];
        if (!song) {
            console.warn(`No song found with name: ${songName}`);
            return;
        }
    
        // Sicherstellen, dass targetVolume im Bereich [0, 1] liegt
        targetVolume = Math.max(0, Math.min(1, targetVolume));
    
        this.fadeSongVolume(song, duration, targetVolume);
    }

    playEffect(name, duration = null, volume = 0.8, loop = false) {
        const effect = this.effectSounds[name];
        if (!effect) return;

        if (effect.isPlaying) {
            effect.stop();
        }

        if (duration !== null) {
            const originalDuration = effect.buffer.duration;
            const playbackRate = originalDuration / duration;
            effect.setPlaybackRate(playbackRate);
        } else {
            effect.setPlaybackRate(1);
        }

        effect.userData.targetVolume = volume; // Setze targetVolume direkt
        this.setTargetVolume(effect, volume); // Verwende setTargetVolume
        effect.setLoop(loop);
        effect.play();
        this.activeEffects.add(name);
    }

    stopEffect(name) {
        const effect = this.effectSounds[name];
        if (effect && effect.isPlaying) {
            effect.stop();
        }
        this.activeEffects.delete(name);
    }

    stopAllEffects() {
        this.activeEffects.forEach(effectName => this.stopEffect(effectName));
    }

    setMasterVolume(volume) {
        this.masterVolume = Math.max(0, Math.min(1, volume));
        this.updateAllVolumes();
    }

    getMasterVolume() {
        return this.masterVolume;
    }

    getEffectiveVolume() {
        return this.isMuted ? 0 : this.masterVolume;
    }

    mute() {
        this.isMuted = true;
        this.updateAllVolumes();
    }

    unmute() {
        this.isMuted = false;
        this.updateAllVolumes();
    }

    updateAllVolumes() {
        const effectiveVolume = this.getEffectiveVolume();
        
        // Aktualisiere Hintergrundtrack
        if (this.currentBackgroundTrack) {
            this.currentBackgroundTrack.setVolume(this.currentBackgroundTrack.userData.targetVolume * effectiveVolume);
        }
        
        // Aktualisiere Songs
        Object.values(this.songs).forEach(song => {
            song.setVolume(song.userData.targetVolume * effectiveVolume);
        });
        
        // Aktualisiere Effekte
        Object.values(this.effectSounds).forEach(effect => {
            if (effect.isPlaying) {
                effect.setVolume(effect.userData.targetVolume * effectiveVolume);
            }
        });
    }

    fadeVolume(duration, targetVolume) {
        const startVolume = this.getMasterVolume();
        const startTime = performance.now();

        const fade = () => {
            const now = performance.now();
            const elapsed = (now - startTime) / 1000;
            const t = Math.min(elapsed / duration, 1);
            
            const currentVolume = startVolume + (targetVolume - startVolume) * t;
            this.setMasterVolume(currentVolume);

            if (t < 1) {
                requestAnimationFrame(fade);
            }
        };

        fade();
    }

    soundOnOff(isSoundOn) {
        if (isSoundOn) {
            this.fadeVolume(1, 1); // Fade in über 1 Sekunde
        } else {
            this.fadeVolume(0.1, 0); // Fade out über 1 Sekunde
        }
    }
}

/**
 * Camera Configuration Object
 */
const cameraConfig = {
    0: {
        desktop: {
            position: { x: 25.575, y: 13.299, z: 18.414 },
            target: { x: -1.8, y: 0, z: 1.0 }
        },
        tablet: {
            position: { x: 29.34, y: 15.39, z: 21.15 },
            target: { x: 0.18, y: 1.08, z: 0.225 }
        },
        mobile: {
            position: { x: 32.6, y: 17.1, z: 23.5 },
            target: { x: 0.2, y: 1.2, z: 0.25 }
        }
    },
    1: {
        desktop: {
            position: { x: -9, y: 14.4, z: 24.4 },
            target: { x: 1, y: 0.6, z: 1.3 }
        },
        tablet: {
            position: { x: -20.7, y: 28.8, z: 33.84 },
            target: { x: -0.9, y: 1.44, z: 0.225 }
        },
        mobile: {
            position: { x: -23.0, y: 32, z: 37.6 },
            target: { x: -1, y: 1.6, z: 0.25 }
        }
    },
    2: {
        desktop: {
            position: { x: -25.024, y: 10, z: -16 },
            target: { x: 1, y: 0.35, z: -1 }
        },
        tablet: {
            position: { x: -28.8, y: 9.9, z: -16.65 },
            target: { x: 0, y: 1.08, z: -0.09 }
        },
        mobile: {
            position: { x: -32, y: 11, z: -18.5 },
            target: { x: 0, y: 1.2, z: -0.1 }
        }
    },
    3: {
        desktop: {
            position: { x: 4, y: 6, z: -24 },
            target: { x: -1.0, y: 0.5, z: -0.5 }
        },
        tablet: {
            position: { x: 4.5, y: 8.1, z: -30.6 },
            target: { x: -0.207, y: 1.08, z: -0.09 }
        },
        mobile: {
            position: { x: 5, y: 9, z: -34},
            target: { x: -0.23, y: 1.2, z: -0.1 }
        }
    },
    4: {
        desktop: {
            position: { x: 2550, y: 0, z: 0 },
            target: { x: -0.8, y: 0.5, z: -36.5 }
        },
        tablet: {
            position: { x: 2350, y: 0, z: 0 },
            target: { x: -0.27, y: 0.9, z: -32.85 }
        },
        mobile: {
            position: { x: 2450, y: 0, z: 0 },
            target: { x: -0.3, y: 1.0, z: -36.5 }
        }
    }
};


/**
 * Camera
 */

const camera = new THREE.PerspectiveCamera(10, sizes.width / sizes.height, 0.25, 2580);
const cameraTarget = new THREE.Vector3();

function updateCameraPosition(section) {
    const deviceType = getDeviceType();
    const sectionConfig = cameraConfig[section][deviceType];

    camera.position.set(
        sectionConfig.position.x,
        sectionConfig.position.y,
        sectionConfig.position.z
    );

    cameraTarget.set(
        sectionConfig.target.x,
        sectionConfig.target.y,
        sectionConfig.target.z
    );

    camera.lookAt(cameraTarget);
}

// Initialize camera to section 0
updateCameraPosition(0);

scene.add(camera);

if (!isMobile) {
    makeItGrain( THREE, camera )
}

const godrayTint        = new THREE.Vector4(0.971,0.905,0.81, 1.0)
const godrayRadialScale = -0.02
const godrayLengthScale = 2.0
const godraySpeed       = 0.018
const godrayIntensity   = 0.0

const godrayMaterial = new THREE.ShaderMaterial({
    vertexShader: godraysVertexShader,
    fragmentShader: godraysFragmentShader,
    transparent: true,
    precision: 'highp',
    blending: THREE.AdditiveBlending,
    uniforms: {
        //uMap: new THREE.Uniform(godrayCausticsTexture),
        uTint       : { value: godrayTint },
        uRadialScale: { value: godrayRadialScale },
        uLengthScale: { value: godrayLengthScale },
        uTime       : { value: 0 },
        uSpeed      : { value: godraySpeed },
        uIntensity  : { value: godrayIntensity },
        uCameraPosition: { value: camera.position }
    }
})

// Vignette Effect

const vignettePlaneGeometry = new THREE.BufferGeometry();
const vertices = new Float32Array([
    -1, -1, 0,
     1, -1, 0,
     1,  1, 0,
    -1, -1, 0,
     1,  1, 0,
    -1,  1, 0
]);
vignettePlaneGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
vignettePlaneGeometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array([
    0, 0,
    1, 0,
    1, 1,
    0, 0,
    1, 1,
    0, 1
]), 2));
const vignettePlaneMaterial = new THREE.ShaderMaterial({
    uniforms: {
        uOpacity: { value: 0.14},
        uMargin: { value: 0.12 },
        uCornerRadius: { value: 0.12 },
        uFade: { value: 0.20 }
    },
    vertexShader: `
        varying vec2 vUv;
        void main() {
            vUv = uv;
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uOpacity;
        uniform float uMargin;
        uniform float uCornerRadius;
        uniform float uFade;
        varying vec2 vUv;

        void main()
        {
            vec2 uv = vUv * 2.0 - 1.0;

            vec2 b = vec2(1.0 - uMargin - uCornerRadius);
            vec2 q = abs(uv) - b;
            float dist = length(max(q, 0.0)) - uCornerRadius;

            float alpha = smoothstep(0.0, uFade, dist);

            vec3 vignetteColor = vec3(0.408, 0.341, 0.286);
            gl_FragColor = vec4(vignetteColor, alpha * uOpacity);
        }
    `,
    transparent: true,
    blending: THREE.NormalBlending,
    depthWrite: false,
    depthTest: false
});

const vignettePlane = new THREE.Mesh(vignettePlaneGeometry, vignettePlaneMaterial);
vignettePlane.renderOrder = 999;
vignettePlane.frustumCulled = false;

camera.add(vignettePlane);
vignettePlane.position.set(0, 0, -0.01);

/**
 * * Audiofiles laden
*/

function initializeAudio() {
    window.audioManager = new OptimizedAudioManager(camera);

    const audioURLs = [
        { method: 'loadSongTrack', args: ['song-instrumental', './sounds/song-instrumental.mp3'] },
        { method: 'loadSongTrack', args: ['song-instrumental-full', './sounds/song-instrumental-full.mp3'] },
        { method: 'loadSongTrack', args: ['song', './sounds/song.mp3'] },
        { method: 'loadBackgroundTrack', args: ['ship-storm', './sounds/storm.mp3'] },
        { method: 'loadBackgroundTrack', args: ['ship-calm', './sounds/calm-sea.mp3'] },
        { method: 'loadEffectSound', args: ['woosh-short', './sounds/woosh-short.mp3'] },
        { method: 'loadEffectSound', args: ['woosh-long', './sounds/woosh-long.mp3'] },
        { method: 'loadEffectSound', args: ['bell-ring', './sounds/bell-ring.mp3'] },
        { method: 'loadEffectSound', args: ['segel', './sounds/segel.mp3'] },
        { method: 'loadEffectSound', args: ['delfin', './sounds/delfin-spritzer.mp3'] },
    ];

    // Manually inform the loadingManager about the audio files
    audioURLs.forEach(audio => {
        loadingManager.itemStart(audio.args[1]);
    });

    const audioPromises = audioURLs.map(audio => {
        return audioManager[audio.method](...audio.args)
            .then(result => {
                loadingManager.itemEnd(audio.args[1]);
                return result;
            })
            .catch(error => {
                loadingManager.itemError(audio.args[1]);
                throw error;
            });
    });

    return Promise.all(audioPromises);
}


/*
Loaders, Counter & Start Experience
*/

// Loaders
const loadingManager = new THREE.LoadingManager(
    // Loaded
    () => {
        document.querySelector('.startword').classList.add('ready');
        document.querySelector('.counter').classList.add('finished');
        document.querySelector('.start-button').addEventListener('click', startExperience);
    },
    // Progress
    (itemURL, itemsLoaded, itemsTotal) => {
        document.querySelector('.ui-first').classList.add('visible');
        const progressRatio = itemsLoaded / itemsTotal;
        updateCounter(progressRatio);
    }
);

// Initialize audio after setting up the loading manager
initializeAudio();

// Counter

// Referenzen zu den Counter-Elementen
const counter1 = document.querySelector(".counter-1");
const counter2 = document.querySelector(".counter-2");
const counter3 = document.querySelector(".counter-3");

// Funktion zum Erstellen der Zahlen für den Counter
function createCounterNumbers(counterElement) {
  counterElement.innerHTML = ''; // Existierende Zahlen löschen
  for (let i = 0; i <= 9; i++) {
    const div = document.createElement("div");
    div.className = "num";
    div.textContent = i;
    counterElement.appendChild(div);
  }
}

// Counter initialisieren
createCounterNumbers(counter1);
createCounterNumbers(counter2);
createCounterNumbers(counter3);

// Funktion zum Aktualisieren des Counters basierend auf progressRatio
function updateCounter(progress) {
  const total = Math.floor(progress * 100); // In Prozent umwandeln
  const digits = String(total).padStart(3, '0').split(''); // 3-stellige Zahl sicherstellen
  const numHeight = counter1.querySelector(".num").clientHeight;

  // Jede Ziffer aktualisieren
  [counter1, counter2, counter3].forEach((counterElement, index) => {
    const digit = parseInt(digits[index]);
    const y = -numHeight * digit;
    gsap.to(counterElement, {
      y: y,
      duration: 0.2,
      ease: "power2.out",
    });
  });
}



// Sound an oder aus?
let isSoundOn = true;

const volumeButton = document.querySelector('.volume-button');
const audioIndicator = document.querySelector('.audio-indicator');

volumeButton.addEventListener('click', (event) => {
    event.preventDefault();
    audioIndicator.classList.toggle('active');
    isSoundOn = !isSoundOn;
    audioManager.soundOnOff(isSoundOn);
    updateAudioState(); // Aktualisiere den Audiozustand bei Klick
});

function updateAudioState() {
    if (document.hidden || !document.hasFocus() || !isSoundOn) {
        audioManager.masterVolume = 0
        audioManager.updateAllVolumes()
    } else {
        audioManager.fadeVolume(1, 1);
        updateVisibility();
    }
}

// Event Listener
document.addEventListener('visibilitychange', updateAudioState);
window.addEventListener('blur', updateAudioState);
window.addEventListener('focus', updateAudioState);


// Start Experience

let experienceStarted = false;

function startExperience(event) {
    event.preventDefault();
    
    if (experienceStarted) return;
    experienceStarted = true;

    // Verwende requestAnimationFrame für die Klassenentfernung
    requestAnimationFrame(() => {
        document.querySelector('.start-button').classList.remove('ready');
        document.querySelector('.ui-first').classList.remove('loading');
        document.querySelector('.footer-bg.section-1').classList.remove('visible');
    });

    // Setzen des Mute-Zustands entsprechend isSoundOn
    audioManager.soundOnOff(isSoundOn);

    // Direkt die Audio-Dateien verwenden, da sie bereits geladen sind
    audioManager.playBackgroundForSection('ship-storm', 1.0, 0.85);
    audioManager.playSongForSection('song-instrumental', 0.0, 0.18);
    audioManager.playSongForSection('song-instrumental-full', 0.0, 0.0);
    audioManager.playSongForSection('song', 0.0, 0.0);

    // GSAP-Animationen
    gsap.delayedCall(1.2, () => {
        document.querySelector('.ui-second').classList.add('visible');
        
        gsap.to('.webgl', { duration: 1.0, opacity: 1, ease: 'power2.inOut' });
        document.querySelector('.textpage').classList.add('visible');
        document.querySelector('.noisefilter').classList.add('disabled');

        //cursor.setText(`<img class="cursor-icon" src="https://bluebox-design.ch/textures/ui/scroll-down-pfeil.svg" width="20px" height="20px">${languageManager.getText('cursor_runter_scrollen')}`);

  
    // Start the animation loop
    requestAnimationFrame(tick);

        gsap.delayedCall(0.4, () => {
            // GSAP für die Einblendung der Gridlines
            document.querySelector('.loading-screen').style.cssText = 'display: none;';
            if (isMobile) {
                document.querySelector('.language-switcher').style.cssText = 'display: none;';
            }
            gsap.to('.gridlines', { duration: 1.5, scaleY: 1.0, ease: 'power2.inOut' });
            gsap.to('.gridlines-quer', { duration: 1.5, scaleX: 1.0, ease: 'power2.inOut' });

            // Aktiviere Scroll- und Touch-Events nach den Animationen
            enableScroll();
        });
    });
}



// Loaders

const textureLoader = new THREE.TextureLoader(loadingManager)

const dracoLoader = new DRACOLoader(loadingManager)
dracoLoader.setDecoderPath('./draco/')

const gltfLoader = new GLTFLoader(loadingManager)
gltfLoader.setDRACOLoader(dracoLoader)



/**
 * Models
 */

// Ship Object3D
const shipPositioner = new THREE.Object3D();
shipPositioner.position.set(0.9, 0.15, 0.9);
shipPositioner.rotation.set(0, Math.PI*1.2, 0);
scene.add(shipPositioner);

let ship;
let shipMixer;
let shipAnimationsSailOpen = {};
let shipAnimationsSpritzer = {};
let shipAnimationsSegelWind = {};
let segelUnten = false;
let emissionIntensity = 0.95;

gltfLoader.load(
    '/models/sailship.glb',
    (gltf) => {
        // Shadow
        gltf.scene.traverse(function (node) {
            if (node.isMesh) { node.castShadow = false; }
            node.frustumCulled = false;
            //node.material = shipSunsetMaterial;
        });
        
        ship = gltf.scene;
        shipPositioner.add(ship);


    // Emission-Wert
        ship.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.material.emissiveIntensity = emissionIntensity;
            }
        });

        // Position
        ship.scale.set(0.15, 0.15, 0.15);
        ship.rotation.set(-0.05, 0, 0);

        shipMixer = new THREE.AnimationMixer(ship);
        const clips = gltf.animations;

        // Helper function to set up animations
        const setupAnimationSailOpen = (name) => {
            const clip = THREE.AnimationClip.findByName(clips, name);
            if (!clip) {
                console.warn(`Animation clip ${name} not found.`);
                return;
            }
            const action = shipMixer.clipAction(clip);
            action.setLoop(THREE.LoopOnce);
            action.clampWhenFinished = true;
            shipAnimationsSailOpen[name] = action;
        };

        // Set up all animations
        setupAnimationSailOpen('01-segel-open-01');
        setupAnimationSailOpen('01-segel-open-02');
        setupAnimationSailOpen('01-segel-open-03');
        setupAnimationSailOpen('01-segel-open-04');
        setupAnimationSailOpen('01-segel-open-05');
        setupAnimationSailOpen('01-segel-open-06');
        setupAnimationSailOpen('01-segel-open-08');

        // Helper function to set up animations
        const setupAnimationSpritzer = (name) => {
            const clip = THREE.AnimationClip.findByName(clips, name);
            if (!clip) {
                console.warn(`Animation clip ${name} not found.`);
                return;
            }
            const action = shipMixer.clipAction(clip);
            action.clampWhenFinished = false;
            shipAnimationsSpritzer[name] = action;
        };

        // Set up all spritzer animations
        setupAnimationSpritzer('spritzer-01');
        setupAnimationSpritzer('spritzer-02');
        setupAnimationSpritzer('spritzer-03');
        setupAnimationSpritzer('spritzer-04');
        setupAnimationSpritzer('spritzer-05');

        // Helper function to set up animations
        const setupAnimationSegelWind = (name) => {
            const clip = THREE.AnimationClip.findByName(clips, name);
            if (!clip) {
                console.warn(`Animation clip ${name} not found.`);
                return;
            }
            const action = shipMixer.clipAction(clip);
            action.clampWhenFinished = false;
            shipAnimationsSegelWind[name] = action;
        };

        // Set up all spritzer animations
        setupAnimationSegelWind('02-segel-wind-01');
        setupAnimationSegelWind('02-segel-wind-02');
        setupAnimationSegelWind('02-segel-wind-03');
        setupAnimationSegelWind('02-segel-wind-04');
        setupAnimationSegelWind('02-segel-wind-05');
        setupAnimationSegelWind('02-segel-wind-06');
        setupAnimationSegelWind('02-segel-wind-08');
    
    }
);

// Function to play all sail open animations
function playSailOpenAnimations() {
    if (!segelUnten) {
        for (let action of Object.values(shipAnimationsSailOpen)) {
            action.reset();
            action.paused = false; 
            action.clampWhenFinished = true;
            action.timeScale = 1;
            action.setLoop(THREE.LoopOnce, 1);
            action.play();
        }
        segelUnten = true;
    }
}

// Function to play all sail open animations in reverse
function playSailOpenAnimationsReverse() {
    if (segelUnten) {
        for (let action of Object.values(shipAnimationsSailOpen)) {
            action.reset();
            action.paused = false;
            action.clampWhenFinished = true;
            action.setLoop(THREE.LoopOnce, 1);
            action.time = action.getClip().duration;
            action.timeScale = -1;
            action.play();
        }
        segelUnten = false;
    }
}

// Function to play Spritzer animations in a loop
function playSpritzerAnimations() {
    for (let action of Object.values(shipAnimationsSpritzer)) {
        action.reset();
        action.paused = false;
        action.clampWhenFinished = false;
        action.timeScale = 1;
        action.play();
    }
}

// Function to stop Spritzer animations
function stopSpritzerAnimations() {
    for (let action of Object.values(shipAnimationsSpritzer)) {
        action.stop();
    }
}

// Function to play Spritzer animations in a loop
function playSegelWindAnimations() {
    if (segelUnten) {
        for (let action of Object.values(shipAnimationsSegelWind)) {
            action.reset();
            action.paused = false;
            action.clampWhenFinished = false;
            action.timeScale = 1;
            action.fadeIn(1.0).play();
        }
    }
}

// Function to stop Spritzer animations
function stopSegelWindAnimations() {
    for (let action of Object.values(shipAnimationsSegelWind)) {
        action.fadeOut(1.0).stop();
    }
}

// Update des Emissionwertes
function updateShipEmission(targetIntensity) {
    gsap.to({ intensity: emissionIntensity }, {
        duration: 2.0,
        intensity: targetIntensity,
        ease: 'none',
        onUpdate: function() {
            emissionIntensity = this.targets()[0].intensity;
            ship.traverse((child) => {
                if (child instanceof THREE.Mesh) {
                    child.material.emissiveIntensity = emissionIntensity;
                }
            });
        }
    });
}


// Dolphin
const dolphinPositioner = new THREE.Object3D();
dolphinPositioner.position.set(0, -2, 0);
dolphinPositioner.rotation.set(0, Math.PI * 1.2, 0);
scene.add(dolphinPositioner);

let dolphin;
let dolphinMixer;
let dolphinAction;
let dolphinAction2;

gltfLoader.load(
    '/models/dolphins.glb',
    (gltf) => {
        // Shadow
        gltf.scene.traverse(function (node) {
            if (node.isMesh) { node.castShadow = false; }
            node.frustumCulled = false;
        });

        dolphin = gltf.scene;
        dolphinPositioner.add(dolphin);
        
        // Position
        dolphin.scale.set(0.010, 0.010, 0.010);
        dolphin.position.set(0, 0, 0);

        dolphinMixer = new THREE.AnimationMixer(dolphin);
        const clips = gltf.animations;
        const clip = THREE.AnimationClip.findByName(clips, 'dolphin-jump');
        dolphinAction = dolphinMixer.clipAction(clip);
        dolphinAction.clampWhenFinished = true;

        const clip2 = THREE.AnimationClip.findByName(clips, 'dolphin-jump-02');
        dolphinAction2 = dolphinMixer.clipAction(clip2);
        dolphinAction2.clampWhenFinished = true;

        dolphinMixer.addEventListener('finished', (e) => {
            if (e.action === dolphinAction) {
                dolphinPositioner.position.set(0, -1, 0);
                scheduleNextDolphinAnimation();
            }
        });

        // Automatische Animation
        scheduleNextDolphinAnimation();
    }
);

function playDolphinAnimation() {
    if (dolphinAction && currentSection != 0 && currentSection != 3 && !dolphinAction.isRunning()) {
        if (currentSection != 0 && currentSection != 2) {
            setRandomDolphinPosition();
        } else {
            setShipDolphinPosition()
        }
        resetDolphinPositionY();
        
        // Reset und Play für beide Aktionen
        [dolphinAction, dolphinAction2].forEach(action => {
            action.reset();
            action.paused = false;
            action.setLoop(THREE.LoopOnce, 1);
            action.time = 0;
            action.play();
        });

        audioManager.playEffect('delfin', 2.1, 0.28, false);

    }
}

function setRandomDolphinPosition() {
    let x, z;

    do {
        x = Math.random() * 3.2 - 1.6;
    } while (x >= 0.3 && x <= 1.5);

    do {
        z = Math.random() * 3.2 - 1.6;
    } while (z >= 0.3 && z <= 1.5);

    dolphinPositioner.position.x = x;
    dolphinPositioner.position.z = z;
    dolphinPositioner.rotation.y = Math.random() * 2 * Math.PI;
}

function setShipDolphinPosition() {
    dolphinPositioner.position.x = shipPositioner.position.x - 0.8;
    dolphinPositioner.position.z = shipPositioner.position.z - 0.6;
    dolphinPositioner.rotation.y = Math.PI * 1.2;
}

function resetDolphinPositionY() {
    const dolphinGridLocationX = Math.floor(16 + (dolphinPositioner.position.x / 0.15));
    const dolphinGridLocationZ = Math.floor(16 + (dolphinPositioner.position.z / 0.15));
    const index = Math.min(Math.max(dolphinGridLocationX * gridSize + dolphinGridLocationZ, 0), count - 1);
    
    // Verwenden Sie getInstanceTransform, um die Position der Instanz zu erhalten
    const instanceTransform = getInstanceTransform(instancedCubeMesh, index);
    const dolphinGridCubePositionY = instanceTransform.position.y;
    
    if (currentSection === 1) {
        dolphinPositioner.position.y = dolphinGridCubePositionY + 0.07;
    } else if (currentSection === 2) {
        dolphinPositioner.position.y = dolphinGridCubePositionY * 2 + 0.03;
    }
}

    function scheduleNextDolphinAnimation() {
        if (currentSection === 1) {
            window.addEventListener('click', playDolphinAnimation);
        } else if (currentSection === 2) {
            window.removeEventListener('click', playDolphinAnimation);
            const delay = 2000 + Math.random() * 4000;
            setTimeout(playDolphinAnimation, delay);
        }
    }



const seagullPositioner = new THREE.Object3D();
seagullPositioner.position.set(shipPositioner.position.x, 1.0, shipPositioner.position.z);
seagullPositioner.rotation.set(0, 0, 0);
scene.add(seagullPositioner);

let seagull;
let seagullMixer;
let seagullAction;

gltfLoader.load(
    '/models/seagull.glb',
    (gltf) => {
        // Shadow
        gltf.scene.traverse(function (node) {
            if (node.isMesh) { node.castShadow = false; }
            node.frustumCulled = false;
           // node.material = waterCubeMaterial;
        });

        seagull = gltf.scene;
        seagullPositioner.add(seagull);
        
        // Position
        seagull.scale.set(0.1, 0.1, 0.1);
        seagull.position.set(-0.6, 0, 0);
        seagull.rotation.set(0, 0, 0);

        seagullMixer = new THREE.AnimationMixer(seagull);
        const clips = gltf.animations;
        const clip = THREE.AnimationClip.findByName(clips, 'fly');
        seagullAction = seagullMixer.clipAction(clip);
        seagullAction.clampWhenFinished = true;
        seagullAction.play();

    }
);

const seagullPositioner2 = new THREE.Object3D();
seagullPositioner2.position.set(shipPositioner.position.x, 1.2, shipPositioner.position.z);
seagullPositioner2.rotation.set(0, Math.PI*0.3, 0);
scene.add(seagullPositioner2);

let seagull2;
let seagullMixer2;
let seagullAction2;

gltfLoader.load(
    '/models/seagull.glb',
    (gltf) => {
        // Shadow
        gltf.scene.traverse(function (node) {
            if (node.isMesh) { node.castShadow = false; }
            node.frustumCulled = false;
           // node.material = waterCubeMaterial;
        });

        seagull2 = gltf.scene;
        seagullPositioner2.add(seagull2);
        
        // Position
        seagull2.scale.set(0.1, 0.1, 0.1);
        seagull2.position.set(0.7, 0, 0);

        seagullMixer2 = new THREE.AnimationMixer(seagull2);
        const clips = gltf.animations;
        const clip = THREE.AnimationClip.findByName(clips, 'fly');
        seagullAction2 = seagullMixer2.clipAction(clip);
        seagullAction2.clampWhenFinished = true;
        seagullAction2.paused = true;
   
            seagullAction2.paused = false; // Entpause die Animation nach der Verzögerung
            seagullAction2.startAt(1.5).play();

    }
);

const seagullPositioner3 = new THREE.Object3D();
seagullPositioner3.position.set(shipPositioner.position.x, 0.8, shipPositioner.position.z);
seagullPositioner3.rotation.set(0, Math.PI*0.7, 0);
scene.add(seagullPositioner3);

let seagull3;
let seagullMixer3;
let seagullAction3;

gltfLoader.load(
    '/models/seagull.glb',
    (gltf) => {
        // Shadow
        gltf.scene.traverse(function (node) {
            if (node.isMesh) { node.castShadow = false; }
            node.frustumCulled = false;
           // node.material = waterCubeMaterial;
        });

        seagull3 = gltf.scene;
        seagullPositioner3.add(seagull3);
        
        // Position
        seagull3.scale.set(0.1, 0.1, 0.1);
        seagull3.position.set(0.5, 0, 0);

        seagullMixer3 = new THREE.AnimationMixer(seagull3);
        const clips = gltf.animations;
        const clip = THREE.AnimationClip.findByName(clips, 'fly');
        seagullAction3 = seagullMixer3.clipAction(clip);
        seagullAction3.clampWhenFinished = true;
        seagullAction3.paused = true;   

            seagullAction3.paused = false; // Entpause die Animation nach der Verzögerung
            seagullAction3.startAt(3.5).play();

    }
);

let islandHouse;
let islandMixer;
let islandAction;
let islandAction2;
let islandAction3;
let islandAction4;
let islandAction5;


gltfLoader.load(
    '/models/island-objects.glb',
    (gltf) => {
        //Shadow
        gltf.scene.traverse(function (node) {
            
            if (node.isMesh) { node.castShadow = false; }
            node.frustumCulled = false;
            //node.material = waterCubeMaterial;
        });

        islandHouse = gltf.scene;
        
        // Position
        islandHouse.scale.set(0.076, 0.076, 0.076);
        islandHouse.position.set(-2.04, -1.5, 2.36);
        islandHouse.rotation.set(0,Math.PI,0);

        islandMixer = new THREE.AnimationMixer(islandHouse);
        const clips = gltf.animations;

        const clip = THREE.AnimationClip.findByName(clips, 'eating');
        islandAction = islandMixer.clipAction(clip);
        islandAction.clampWhenFinished = true;
        islandAction.play();

        const clip2 = THREE.AnimationClip.findByName(clips, 'eating-02');
        islandAction2 = islandMixer.clipAction(clip2);
        islandAction2.clampWhenFinished = true;
        islandAction2.startAt(3);
        islandAction2.play();

        const clip3 = THREE.AnimationClip.findByName(clips, 'windrad');
        islandAction3 = islandMixer.clipAction(clip3);
        islandAction3.clampWhenFinished = true;
        islandAction3.play();

        const clip4 = THREE.AnimationClip.findByName(clips, 'flag-big-waving');
        islandAction4 = islandMixer.clipAction(clip4);
        islandAction4.clampWhenFinished = true;
        islandAction4.play();

        const clip5 = THREE.AnimationClip.findByName(clips, 'flag-tower-waving');
        islandAction5 = islandMixer.clipAction(clip5);
        islandAction5.clampWhenFinished = true;
        islandAction5.play();

        scene.add(islandHouse);
    });

let islandRock;
gltfLoader.load(
    '/models/island.glb',
    (gltf) => {
        //Shadow
        gltf.scene.traverse(function (node) {
            
            if (node.isMesh) { node.castShadow = false; }
            node.frustumCulled = false;
            node.material = rockObjectsMaterial;
        });

        islandRock = gltf.scene;
        scene.add(islandRock);

        console.log(islandRock)

        // Position
        islandRock.scale.set(0.076, 0.076, 0.076);
        islandRock.position.set(-2.00, -1.0, 2.40);
        islandRock.rotation.set(0, Math.PI*1, 0);
    },
    undefined,
    (error) => {
        console.error('Ein Fehler beim Laden des OBJ-Modells ist aufgetreten: ', error);
    }

);


let blueBox;
gltfLoader.load(
    '/models/bluebox-design-logo.glb',
    (gltf) => {
        //Shadow
        gltf.scene.traverse(function (node) {
            
            if (node.isMesh) { node.castShadow = false; }
            node.frustumCulled = false;
            node.material = waterCubeMaterial;
        });

        blueBox = gltf.scene;
        scene.add(blueBox)

        // Positioniere das Modell
        blueBox.scale.set(4.8, 4.8, 4.8);
        blueBox.position.set(0, -0.075, 0);
        blueBox.rotation.set(0, Math.PI * 0.5, 0);
    },
    undefined,
    (error) => {
        console.error('Ein Fehler beim Laden des OBJ-Modells ist aufgetreten: ', error);
    }
)



/**
 * Water
 */

// Color
const mainColor = new THREE.Color('#f7e2c2');
const bbdColor = new THREE.Color('#0fa9dc');
const objectColor = new THREE.Color('#f6e5c7');
let transitionProgress = 0;

const depthColor = '#f0e1c4'
const surfaceColor = '#f8e7ca'

// Textures

function loadLanguageTextures(baseName, fileExtension, languages) {
    const textures = {};
    languages.forEach((lang) => {
      const texture = textureLoader.load(`./textures/${baseName}-${lang}.${fileExtension}`);
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.anisotropy = 8;
      textures[lang] = texture;
    });
    return textures;
  }

const languages = ['de', 'en', 'fr'];

// Laden der lineTextures
const lineTextures = loadLanguageTextures('meer', 'png', languages);

// Laden der borderTextures
const borderTextures = loadLanguageTextures('seekarte', 'png', languages);

const waterNoiseTexture = textureLoader.load('./textures/water-noise.png')
//waterNoiseTexture.colorSpace = THREE.SRGBColorSpace
waterNoiseTexture.wrapS = THREE.RepeatWrapping
waterNoiseTexture.wrapT = THREE.RepeatWrapping
waterNoiseTexture.anisotropy = 8

const portTexture = textureLoader.load('./textures/port.png')
//portTexture.colorSpace = THREE.SRGBColorSpace
portTexture.wrapS = THREE.RepeatWrapping
portTexture.wrapT = THREE.RepeatWrapping
//portTexture.minFilter = THREE.LinearFilter;
//portTexture.magFilter = THREE.LinearFilter;
portTexture.anisotropy = 8

const cloudTexture = textureLoader.load('./textures/cloud.png')
//portTexture.colorSpace = THREE.SRGBColorSpace
cloudTexture.wrapS = THREE.RepeatWrapping
cloudTexture.wrapT = THREE.RepeatWrapping
//portTexture.minFilter = THREE.LinearFilter;
//portTexture.magFilter = THREE.LinearFilter;
cloudTexture.anisotropy = 8

const rockObjectsMaterial = new THREE.MeshStandardMaterial ({
    color: objectColor,
    metalness: 0,
    roughness: 1
})

// Anzahl der Instanzen
const gridSize = 32;
const count = gridSize * gridSize;
const cubeSize = 0.15;
const gap = 0;

// Erstellen Sie die Geometrie und das Material
const boxGeometry = new THREE.BoxGeometry(cubeSize, cubeSize, cubeSize);
boxGeometry.deleteAttribute('uv');
boxGeometry.deleteAttribute('tangent');
boxGeometry.deleteAttribute('color');

const waterCubeMaterial = new THREE.MeshStandardMaterial({
    color: mainColor,
    metalness: 0,
    roughness: 1
});

// Erstellen des Instanced Mesh
const instancedCubeMesh = new THREE.InstancedMesh(boxGeometry, waterCubeMaterial, count);

// Mittelpunkt des Würfelgitters berechnen
const offsetCube = (gridSize / 2) * (cubeSize + gap) - (cubeSize + gap) / 2;

const dummy = new THREE.Object3D();

let indexCube = 0;
for (let i = 0; i < gridSize; i++) {
    for (let j = 0; j < gridSize; j++) {
        // Position des Würfels berechnen
        const x = (cubeSize + gap) * i - offsetCube;
        const y = 0; // Anfangsposition in Y
        const z = (cubeSize + gap) * j - offsetCube;

        // Dummy-Objekt verwenden, um Position zu setzen
        dummy.position.set(x, y, z);
        dummy.updateMatrix();

        // Matrix für die Instanz setzen
        instancedCubeMesh.setMatrixAt(indexCube, dummy.matrix);

        indexCube++;
    }
}

// Schatteneinstellungen
instancedCubeMesh.receiveShadow = true;
instancedCubeMesh.castShadow = false;

// Abrufen der Matrix für die Berechnung der Position
function getInstanceTransform(instancedMesh, index) {
    const matrix = new THREE.Matrix4();
    instancedMesh.getMatrixAt(index, matrix);
    
    const position = new THREE.Vector3();
    const quaternion = new THREE.Quaternion();
    const scale = new THREE.Vector3();
    
    matrix.decompose(position, quaternion, scale);
    
    const rotation = new THREE.Euler().setFromQuaternion(quaternion);
    
    return { position, rotation };
}



// Fügen Sie das Instanced Mesh zur Szene hinzu
scene.add(instancedCubeMesh);
// Anzahl der Instanzen
const planeSize = cubeSize;

// Globales Array für die Planes
const planes = [];
const planeYPosition = isMobile ? -0.076 : -0.079;

// Erstellen Sie die Geometrie und das Material
const planeGeometry = new THREE.PlaneGeometry(planeSize, planeSize, 1, 1);
planeGeometry.rotateX(Math.PI * 0.5); // Rotiert die Geometrie um 90 Grad

const planeMaterial = new THREE.ShaderMaterial({
    vertexShader: waterVertexShader,
    fragmentShader: waterFragmentShader,
    precision: 'highp',
    uniforms: {
        uTime: { value: 0 },
        uWaterNoiseTexture: { value: waterNoiseTexture },
        uWaterNoiseVisibility: { value: 0.0 },
        uLineTexture: { value: lineTextures[languageManager.currentLanguage] },
        uLineColor: { value: new THREE.Color('#685749') },//#615448
        uLineVisibility: { value: 0.0 },
        uBorderTexture: { value: borderTextures[languageManager.currentLanguage] },
        uBorderSize: { value: 32.0 },
        uBorderVisibility: { value: 0.0 },
        uPortTexture: { value: portTexture },
        uPortVisibility: { value: 0.0 },
        uWavesElevation: { value: 0.2 },
        uWavesFrequency: { value: new THREE.Vector2(2, 1) },
        uDepthColor: { value: new THREE.Color(depthColor) },
        uSurfaceColor: { value: new THREE.Color(surfaceColor) },
        uColorOffset: { value: 0.1 },
        uAlpha: { value: 1.0 },
        uShipMovement: { value: new THREE.Vector2(0, 0) }
    },
    side: THREE.DoubleSide,
    transparent: true,
});

// Erstellen des Instanced Mesh
const instancedMesh = new THREE.InstancedMesh(planeGeometry, planeMaterial, count);

// Per-Instance-Attribute erstellen
const offsets = [];
const planeIndices = [];

const offsetPlanes = (planeSize + gap) * (gridSize - 1) / 2;

let indexPlane = 0;
for (let i = 0; i < gridSize; i++) {
    for (let j = 0; j < gridSize; j++) {
        // Position berechnen
        const x = (planeSize + gap) * i - offsetPlanes;
        const y = planeYPosition;
        const z = (planeSize + gap) * j - offsetPlanes;

        // Matrix für die Instanz setzen
        const matrix = new THREE.Matrix4();
        matrix.setPosition(x, y, z);
        instancedMesh.setMatrixAt(indexPlane, matrix);

        // Per-Instance-Daten sammeln
        offsets.push(x, y, z);
        planeIndices.push(i, j);

        indexPlane++;
    }
}

// Per-Instance-Attribute hinzufügen
const offsetAttribute = new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3);
const planeIndexAttribute = new THREE.InstancedBufferAttribute(new Float32Array(planeIndices), 2);

instancedMesh.geometry.setAttribute('offset', offsetAttribute);
instancedMesh.geometry.setAttribute('planeIndex', planeIndexAttribute);

scene.add(instancedMesh);


// Godrays

let godray;
gltfLoader.load(
    '/models/godray.glb',
    (gltf) => {
        //Shadow
        gltf.scene.traverse(function (node) {
            
            if (node.isMesh) { node.castShadow = false; }
            node.frustumCulled = false;
            node.material = godrayMaterial;
        });

        godray = gltf.scene
        scene.add(godray)

        if (!isMobile) {
            godray.scale.set(0.42, 0.42, 0.42);
            godray.position.set(-0.7, 1, 2.0);
            godray.rotation.set(Math.PI * 0.20, 0, 0);
        } else {
            godray.scale.set(0.46, 0.46, 0.46);
            godray.position.set(-2, 2.7, 3);
            godray.rotation.set(Math.PI * 0.10, 0, 0);
        }
        
    },
    undefined,
    (error) => {
        console.error('Ein Fehler beim Laden des OBJ-Modells ist aufgetreten: ', error);
    }
)



// Sun Plane

const sunPlaneSize = 4.2;

const sunPlaneGeometry = new THREE.PlaneGeometry(sunPlaneSize, sunPlaneSize, 1,1);
sunPlaneGeometry.deleteAttribute('tangent')
sunPlaneGeometry.deleteAttribute('color');

const sunPlaneMaterial = new THREE.ShaderMaterial({

    vertexShader: sunVertexShader,
    fragmentShader: sunFragmentShader,
    precision: 'highp',
    uniforms: {
        uScale: new THREE.Uniform(new THREE.Vector2(sunPlaneSize, sunPlaneSize)),
        uSunAlpha: new THREE.Uniform(0.0)
    },
    side: THREE.DoubleSide,
    transparent: true
});

const sunPlane = new THREE.Mesh(sunPlaneGeometry, sunPlaneMaterial);
sunPlane.rotation.y = Math.PI * 0.33;
sunPlane.position.x = 3.5;
sunPlane.position.y = -0.2;
sunPlane.position.z = 2.7;
sunPlane.castShadow = false;
sunPlane.frustumCulled = false;

// Shine Plane

const shinePlaneSize = 4.2;

const shinePlaneGeometry = new THREE.PlaneGeometry(shinePlaneSize, shinePlaneSize, 1,1);
shinePlaneGeometry.deleteAttribute('tangent')
shinePlaneGeometry.deleteAttribute('color')

const shinePlaneMaterial = new THREE.ShaderMaterial({
    vertexShader: shineVertexShader,
    fragmentShader: shineFragmentShader,
    precision: 'mediump',
    uniforms: {
        uScale: new THREE.Uniform(new THREE.Vector2(shinePlaneSize, shinePlaneSize)),
        uShineAlpha: new THREE.Uniform(0.0)
    },
    side: THREE.DoubleSide,
    transparent: true
});

const shinePlane = new THREE.Mesh(shinePlaneGeometry, shinePlaneMaterial);
shinePlane.rotation.y = Math.PI*1.88;
shinePlane.position.x = -1.8;
shinePlane.position.y = 0.0;
shinePlane.position.z = 3.0;
shinePlane.castShadow = false;
shinePlane.frustumCulled = false;

scene.add(shinePlane);

 
// Cloud

const cloudPlaneGeometry = new THREE.PlaneGeometry(2, 2, 1, 1);
cloudPlaneGeometry.deleteAttribute('normal')
cloudPlaneGeometry.deleteAttribute('tangent')
cloudPlaneGeometry.deleteAttribute('color')

const cloudPlaneMaterial = new THREE.ShaderMaterial({
    vertexShader: cloudVertexShader,
    fragmentShader: cloudFragmentShader,
    precision: 'highp',
    uniforms: {
        uCloudTexture: new THREE.Uniform(cloudTexture),
        uAspectRatio: { value: sizes.width / sizes.height },
        //uCloudScale: new THREE.Uniform(new THREE.Vector2(cloudPlaneSizeX, cloudPlaneSizeY)),
        uCloudAlpha: new THREE.Uniform(1.0),
        uTime: new THREE.Uniform(0),
        uCloudMove: new THREE.Uniform(0),
    },
    side: THREE.DoubleSide,
    transparent: true
});

const cloudPlane = new THREE.Mesh(cloudPlaneGeometry, cloudPlaneMaterial);
cloudPlane.rotation.y = Math.PI * 0.2;
cloudPlane.position.x = 6;
cloudPlane.position.y = 4;
cloudPlane.position.z = 6;
cloudPlane.castShadow = false;
cloudPlane.frustumCulled = false;

scene.add(cloudPlane);

//Possibility

let animationInterval;
let currentIndex = null;
let animationRunning = false; 

function flyingPossibilities() {
  if (animationRunning) {
    return; 
  }
  animationRunning = true;

  document.querySelector('.possibilities').classList.add('visible');
  const possibilities = document.querySelectorAll('.possibility');

  if (currentIndex === null) {
    currentIndex = Math.floor(Math.random() * possibilities.length);
  } else {
    possibilities[currentIndex].classList.remove('animate');
  }

  function animateNext() {
    possibilities[currentIndex].classList.remove('animate');
    currentIndex = (currentIndex + 1) % possibilities.length;
    possibilities[currentIndex].classList.add('animate');
  }

  animateNext();
  animationInterval = setInterval(animateNext, 5000);
}

function flyingPossibilitiesStop() {
  if (!animationRunning) {
    return;
  }
  animationRunning = false;

  clearInterval(animationInterval);
  document.querySelector('.possibilities').classList.remove('visible');

  // Verzögert das Entfernen der 'animate'-Klasse um 1 Sekunde
  setTimeout(() => {
    const possibilities = document.querySelectorAll('.possibility');
    possibilities.forEach(el => {
      el.classList.remove('animate');
    });
  }, 1000);
}

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xfefbfa, 2.1)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.35)
directionalLight.castShadow = false
directionalLight.position.set(-10, 10, 10)
scene.add(directionalLight)

const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1.1)
directionalLight2.castShadow = false
directionalLight2.position.set(10, 3, -10)
scene.add(directionalLight2)

const directionalLight3 = new THREE.DirectionalLight(0xffffff, 0.3)
directionalLight3.castShadow = false
directionalLight3.position.set(0, -5, 5)
scene.add(directionalLight3)

const directionalLight4 = new THREE.DirectionalLight(0xffffff,0.6)
directionalLight4.castShadow = false
directionalLight4.position.set(5, -8, 0)    
scene.add(directionalLight4)


// Lampe 1
const pointLight1 = new THREE.PointLight(0xfcfbfa,0)
pointLight1.position.set(-2.5, 0.70, -1.4)
scene.add(pointLight1)

// Lampe 2
const pointLight2 = new THREE.PointLight(0xfcfbfa,0)
pointLight2.position.set(-3.1, 1, -0.3)
scene.add(pointLight2)

// Lampe 3
const pointLight3 = new THREE.PointLight(0xfcfbfa,0);
pointLight3.position.set(-2.5, 0.70, -1.4);
scene.add(pointLight3)

const startPos1 = new THREE.Vector3(-2.8, 0.70, -1.7);
const endPos1 = new THREE.Vector3(1.7, 1.42, 2.9);
const startPos2 = new THREE.Vector3(-3.3, 1.00, -0.5);
const endPos2 = new THREE.Vector3(-0.9, 1.10, 3.3);

const duration1 = 14;
const duration2 = 10;

/*
Mouse
*/
const mouse = new THREE.Vector2()
const cursorPosition = { x: 0, y: 0 };

window.addEventListener('mousemove', (event) =>
{
    mouse.x = event.clientX / sizes.width * 2 - 1
    mouse.y = - (event.clientY / sizes.height * 2 - 1)

    cursorPosition.x = (event.clientX / sizes.width) - 0.5;
    cursorPosition.y = (event.clientY / sizes.height) - 0.5;
}) 

/*
Gyro-Daten
*/

// Speicher für die Geräteorientierung
let deviceOrientation = {
    alpha: 0, // Rotation um die Z-Achse
    beta: 0,  // Neigung nach vorne/rückwärts
    gamma: 0  // Neigung nach links/rechts
};



/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true,
    powerPreference: "high-performance"
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, targetPixelRatio))

// Scroll & Animation

let currentSection = 0;
let lastSection = 0;
let cameraMoving = false;
const maxSection = 4;
const minSection = 0;
let touchStartY = 0;
let isScrollEnabled = false;
let scrolling = false;
let scrollTimeout;

// Funktion zum Aktivieren der Scroll-Event-Listener
function enableScroll() {
    if (!isScrollEnabled) {
        window.addEventListener('wheel', onScroll);
        window.addEventListener('touchstart', onTouchStart);
        window.addEventListener('touchmove', onTouchMove);
        window.addEventListener('touchend', onTouchEnd);
        isScrollEnabled = true;
    }
}

function changeSection(deltaY) {
    if (cameraMoving || !isScrollEnabled) return;

    let newSection = currentSection;

    if (deltaY > 0) {
        if (currentSection < maxSection) {
            newSection++;
        }
    } else if (deltaY < 0) {
        if (currentSection > minSection) {
            newSection--;
        }
    }

    if (newSection !== currentSection) {
        lastSection = currentSection;
        currentSection = newSection;
        startScrollAnimation();
    }
}

function onScroll(event) {
    if (cameraMoving || !isScrollEnabled) return;

    if (!scrolling) {
        scrolling = true;
        changeSection(event.deltaY);
    }
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
        scrolling = false;
    }, 200);
}

function onTouchStart(event) {
    touchStartY = event.touches[0].clientY;
}

function onTouchMove(event) {
    let touchEndY = event.touches[0].clientY;
    let deltaY = touchStartY - touchEndY;

    if (Math.abs(deltaY) > 15) { // Scroll nach oben oder unten
        if (!scrolling) {
            scrolling = true;
            changeSection(deltaY);
        }
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
            scrolling = false;
        }, 200);
        touchStartY = touchEndY; // Reset touch start position
    }
}

function onTouchEnd(event) {
    scrolling = false;
}

let hasShownInstruction1 = false;
let hasShownInstruction2 = false;
let instructionTimer;

function updateInstructions(currentSection) {
if (currentSection === 1 && !hasShownInstruction1) {
    const instruction = document.querySelector('.instruction-01');
    instruction.classList.add('visible');
    hasShownInstruction1 = true;

    // Starte den Timer für 4 Sekunden
    instructionTimer = setTimeout(() => {
        instruction.classList.remove('visible');
    }, 6000);
} else if (currentSection !== 1 && hasShownInstruction1) {
    // Wenn wir die erste Sektion verlassen und die Anweisung noch sichtbar ist
    clearTimeout(instructionTimer);
    document.querySelector('.instruction-01').classList.remove('visible');
}

// Logik für die erste Sektion
if (currentSection === 2 && !hasShownInstruction2) {
    const instruction = document.querySelector('.instruction-02');
    instruction.classList.add('visible');
    hasShownInstruction2 = true;

    // Starte den Timer für 4 Sekunden
    instructionTimer = setTimeout(() => {
        instruction.classList.remove('visible');
    }, 6000);
} else if (currentSection !== 1 && hasShownInstruction2) {
    // Wenn wir die erste Sektion verlassen und die Anweisung noch sichtbar ist
    clearTimeout(instructionTimer);
    document.querySelector('.instruction-02').classList.remove('visible');
}
}

function updateSectionCounter(currentSection) {
    // Entferne 'active' Klasse von allen Abschnittnummern
    document.querySelectorAll('.current-section-number').forEach((number) => {
        number.classList.remove('active');
    });

    // Füge 'active' Klasse zur aktuellen Abschnittnummer hinzu
    setTimeout(() => {
        const currentPoint = document.querySelector(`.number-0${currentSection + 1}`);
        if (currentPoint) {
            currentPoint.classList.add('active');
        }
    }, 50);
}

function updateSectionText(currentSection) {

    setTimeout(() => {
        document.querySelectorAll('.textarea').forEach((section) => {
            section.classList.remove('visible');
        });

        setTimeout(() => {
            const currentPoint = document.querySelector(`.textarea.section-0${currentSection + 1}`);
            if (currentPoint) {
                currentPoint.classList.add('visible');
            }
        }, 1000);

    }, 100);
}

function addSunToScene() {
    if (sunPlane) {
        scene.add(sunPlane);
    }
}

function removeSunFromScene() {
    if (sunPlane) {
        scene.remove(sunPlane);
    }
}

function addShineToScene() {
    if (shinePlane) {
        scene.add(shinePlane);
    }
}

function removeShineFromScene() {
    if (shinePlane) {
        scene.remove(shinePlane);
    }
}

function addCloudToScene() {
    if (cloudPlane) {
        scene.add(cloudPlane);
    }
}

function removeCloudFromScene() {
    if (cloudPlane) {
        scene.remove(cloudPlane);
    }
}

// Sektion 3 Camera-Moving-Parameter
let cameraFinalPosition = new THREE.Vector3();
let isInitialAnimationComplete = false;
let lastCursorPosition = { x: 0, y: 0 };
let lerpedCameraPosition = new THREE.Vector3();
const intensityValue = isMobile ? 0.35 : 0.38;

function startScrollAnimation() {
    const deviceType = getDeviceType();

    if (currentSection < 1 && lastSection === 1 && !cameraMoving) {
        cameraMoving = true;

        // Section Counter Animation
        updateSectionCounter(currentSection);
        updateSectionText(currentSection);
        document.querySelector('.instruction-01').classList.remove('visible');

        // Audioanpassungen
        audioManager.playBackgroundForSection('ship-storm', 1.0, 0.85);
        audioManager.playSongForSection('song-instrumental', 2.0, 0.18);
        audioManager.update();

        const masterTimeline = gsap.timeline();

        const sectionConfig = cameraConfig[0][deviceType];

        // Kamera- und Szene-Animationen
        const cameraTimeline = gsap.timeline({
            onStart: () => {
                gsap.to('.gridlines', { duration: 1.0, scaleY: 0.0, ease: 'power2.inOut' });
                gsap.to('.gridlines-quer', { duration: 1.0, scaleX: 0.0, ease: 'power2.inOut' });
                gsap.to('.gridlines', { duration: 1.0, delay: 1.5, scaleY: 1.0, ease: 'power2.inOut' });
                gsap.to('.gridlines-quer', { duration: 1.0, delay: 1.5, scaleX: 1.0, ease: 'power2.inOut' });
                updateShipEmission(0.95);
                cursor.removeText();
                cursor.removeState('-text');
                document.querySelector('.scroll-icon.up').classList.remove('visible');
                audioManager.playEffect('woosh-short', 3.0, 0.7);
            },
            onComplete: () => {
                        //cursor.setText(`<img class="cursor-icon" src="https://bluebox-design.ch/textures/ui/scroll-down-pfeil.svg" width="20px" height="20px">${languageManager.getText('cursor_runter_scrollen')}`);
                audioManager.stopEffect('woosh-short');
                playSailOpenAnimationsReverse();
                gsap.killTweensOf(camera.position, cameraTarget);
                cameraMoving = false;
            }
        });

        cameraTimeline
            .to(camera.position, {
                duration: 3,
                ease: 'power2.inOut',
                x: sectionConfig.position.x,
                y: sectionConfig.position.y,
                z: sectionConfig.position.z,
                onUpdate: () => {
                    camera.lookAt(cameraTarget);
                }
            }, 0)
            .to(cameraTarget, {
                duration: 3,
                ease: 'power2.inOut',
                x: sectionConfig.target.x,
                y: sectionConfig.target.y,
                z: sectionConfig.target.z,
                onUpdate: () => {
                    camera.lookAt(cameraTarget);
                }
            }, 0);

        masterTimeline.add(cameraTimeline, 0);

        // Schiffneigung
        masterTimeline.to(neigungsKorrektur, {
            duration: 2.0,
            ease: 'linear',
            value: 0.07
        }, 0)

        // Wasser- und Materialanimationen
        const waterTimeline = gsap.timeline();

        waterTimeline
            .to(godrayMaterial.uniforms.uIntensity, {
                value: 0.0,
                duration: 1.5,
                ease: 'power1.inOut',
                onComplete: addCloudToScene
            }, 0)
            .to(planeMaterial.uniforms.uWavesElevation, {
                value: 0.22,
                duration: 3,
                ease: 'power2.inOut',
                onUpdate: syncWaterUniforms
            }, 0)
            .to(planeMaterial.uniforms.uWavesFrequency.value, {
                x: 2.0,
                duration: 3,
                ease: 'power2.inOut',
                onUpdate: syncWaterUniforms
            }, 0)
            .to(planeMaterial.uniforms.uBorderVisibility, {
                value: 0,
                delay: 0.5,
                duration: 1.5,
                ease: 'power1.inOut',
                onUpdate: syncWaterUniforms
            }, 0)
            .to(planeMaterial.uniforms.uColorOffset, {
                value: 0.1,
                duration: 3.0,
                ease: 'power2.inOut',
                onUpdate: syncWaterUniforms
            }, 0)
            .to(cloudPlaneMaterial.uniforms.uCloudAlpha, {
                value: 1,
                duration: 1.5,
                delay: 1.0,
                ease: 'power2.inOut'
            }, 0)
            .to(cloudPlaneMaterial.uniforms.uCloudMove, {
                duration: 0.1,
                ease: 'power2.inOut',
                value: 0,
            }, 0);

        masterTimeline.add(waterTimeline, 0);

        function syncWaterUniforms() {
            planes.forEach(plane => {
                plane.material.uniforms.uWavesElevation.value = planeMaterial.uniforms.uWavesElevation.value;
                plane.material.uniforms.uWavesFrequency.value.x = planeMaterial.uniforms.uWavesFrequency.value.x;
                plane.material.uniforms.uBorderVisibility.value = planeMaterial.uniforms.uBorderVisibility.value;
                plane.material.uniforms.uColorOffset.value = planeMaterial.uniforms.uColorOffset.value;
            });
        }
    }


    if (currentSection === 1 && !cameraMoving) {
        cameraMoving = true;

        // Section Counter Animation
        updateSectionCounter(currentSection);
        updateSectionText(currentSection);
        flyingPossibilitiesStop()
        document.querySelector('.instruction-02').classList.remove('visible');

        // Audioanpassungen
        audioManager.adjustSongVolume('song-instrumental', 2.0, 0.15);
        audioManager.adjustSongVolume('song', 2.0, 0.0);
        audioManager.stopEffect('segel');

        const masterTimeline = gsap.timeline();

        const sectionConfig = cameraConfig[1][deviceType];

        // Kamera- und Szene-Animationen
        const cameraTimeline = gsap.timeline({
            onStart: () => {
                gsap.to('.gridlines', { duration: 1.0, scaleY: 0.0, ease: 'power2.inOut' });
                gsap.to('.gridlines-quer', { duration: 1.0, scaleX: 0.0, ease: 'power2.inOut' });
                gsap.to('.gridlines', { duration: 1.0, delay: 1.5, scaleY: 1.0, ease: 'power2.inOut' });
                gsap.to('.gridlines-quer', { duration: 1.0, delay: 1.5, scaleX: 1.0, ease: 'power2.inOut' });
                cursor.removeText();
                cursor.removeState('-text');
                audioManager.playEffect('woosh-short', 3.4, 0.7);
                stopSegelWindAnimations();
                stopSpritzerAnimations();
                updateShipEmission(1.02);
                if (!isMobile) {
                cameraFinalPosition.copy(camera.position);
                lerpedCameraPosition.copy(camera.position);
                isInitialAnimationComplete = false;
                lastCursorPosition.x = cursorPosition.x;
                lastCursorPosition.y = cursorPosition.y;
                }
            },
            onComplete: () => {
                cursor.setText(`<img class="cursor-icon" src="./textures/ui/delfine.svg" width="20px" height="20px">${languageManager.getText('cursor_klicken_fuer_delfine')}`);
                document.querySelector('.scroll-icon.up').classList.add('visible');
                audioManager.stopEffect('woosh-short');
                audioManager.playBackgroundForSection('ship-calm', 1.0, 1.1);
                audioManager.update();
                playSailOpenAnimations();
                updateInstructions(currentSection),
                cameraMoving = false;

            if (!isMobile) {
                cameraFinalPosition.copy(camera.position);
                lerpedCameraPosition.copy(camera.position);
                isInitialAnimationComplete = true;
                lastCursorPosition.x = cursorPosition.x;
                lastCursorPosition.y = cursorPosition.y;
            }}
        });

        cameraTimeline.to(camera.position, {
            duration: 2.5,
            ease: 'power2.inOut',
            x: sectionConfig.position.x,
            y: sectionConfig.position.y,
            z: sectionConfig.position.z,
            onUpdate: () => {
                camera.lookAt(cameraTarget);
            }
        }, 0);

        cameraTimeline.to(cameraTarget, {
            duration: 2.5,
            ease: 'power2.inOut',
            x: sectionConfig.target.x,
            y: sectionConfig.target.y,
            z: sectionConfig.target.z,
            onUpdate: () => {
                camera.lookAt(cameraTarget);
            }
        }, 0);

        masterTimeline.add(cameraTimeline, 0);

        // Schiffsrotation
        masterTimeline.to(shipPositioner.rotation, {
            duration: 4,
            ease: 'power2.inOut',
            y: Math.PI * 1.2
        }, 0)
        masterTimeline.to(neigungsKorrektur, {
            duration: 2.0,
            ease: 'linear',
            value: 0.0
        }, 0)

        // Wasser- und Materialanimationen
        const waterTimeline = gsap.timeline();

        waterTimeline.to(planeMaterial.uniforms.uWavesElevation, {
            value: 0.07,
            duration: 2.0,
            ease: 'power1.inOut',
            onUpdate: syncWaterUniforms
        }, 1.5);

        waterTimeline.to(planeMaterial.uniforms.uWavesFrequency.value, {
            x: 1.7,
            duration: 2.0,
            ease: 'power1.inOut',
            onUpdate: syncWaterUniforms,
            onComplete: playDolphinAnimation
        }, 1.5);

        waterTimeline.to(planeMaterial.uniforms.uColorOffset, {
            value: 1.2,
            duration: 2.0,
            ease: 'power1.inOut',
            onUpdate: syncWaterUniforms
        }, 1.0);

        waterTimeline.to(planeMaterial.uniforms.uBorderVisibility, {
            value: 1,
            duration: 1.5,
            ease: 'power1.inOut',
            onUpdate: syncWaterUniforms
        }, 2.0);

        waterTimeline.to(planeMaterial.uniforms.uLineVisibility, {
            value: 0,
            duration: 1.5,
            ease: 'power1.inOut',
            onUpdate: syncWaterUniforms
        }, 0.5);

        waterTimeline.to(planeMaterial.uniforms.uBorderSize, {
            value: 32.0,
            duration: 1.5,
            ease: 'power1.inOut',
            onUpdate: syncWaterUniforms
        }, 0.5);

        masterTimeline.add(waterTimeline, 0);

        function syncWaterUniforms() {
            planes.forEach(plane => {
                plane.material.uniforms.uWavesElevation.value = planeMaterial.uniforms.uWavesElevation.value;
                plane.material.uniforms.uWavesFrequency.value.x = planeMaterial.uniforms.uWavesFrequency.value.x;
                plane.material.uniforms.uColorOffset.value = planeMaterial.uniforms.uColorOffset.value;
                plane.material.uniforms.uBorderVisibility.value = planeMaterial.uniforms.uBorderVisibility.value;
                plane.material.uniforms.uLineVisibility.value = planeMaterial.uniforms.uLineVisibility.value;
                plane.material.uniforms.uBorderSize.value = planeMaterial.uniforms.uBorderSize.value;
            });
        }

        // Wolken- und Sonnenanimationen
        const environmentTimeline = gsap.timeline();

        environmentTimeline.to(cloudPlaneMaterial.uniforms.uCloudMove, {
            duration: 1.5,
            ease: 'power2.inOut',
            value: 1,
        }, 0);

        environmentTimeline.to(cloudPlaneMaterial.uniforms.uCloudAlpha, {
            duration: 1.5,
            ease: 'power2.inOut',
            value: 0,
            onComplete: removeCloudFromScene
        }, 0);

        environmentTimeline.to(sunPlaneMaterial.uniforms.uSunAlpha, {
            value: 0.0,
            duration: 1.5,
            ease: 'power2.inOut',
            onComplete: removeSunFromScene
        }, 0);

        environmentTimeline.to(godrayMaterial.uniforms.uIntensity, {
            value: 0.04,
            duration: 1.5,
            ease: 'power1.inOut'
        }, 1.5);

        masterTimeline.add(environmentTimeline, 0);

        // Möwenanimation
        masterTimeline.to([seagull.rotation, seagull2.rotation, seagull3.rotation], {
            duration: 1.5,
            ease: 'power1.inOut',
            y: 0
        }, 0);
    }

    // Section 2

        if (currentSection === 2 && !cameraMoving) {
        cameraMoving = true;
        addSunToScene();

        // Section Counter Animation
        updateSectionCounter(currentSection);
        updateSectionText(currentSection);
        document.querySelector('.instruction-01').classList.remove('visible');

        // Audio adjustments
        audioManager.playBackgroundForSection('ship-storm', 1.0, 0.85);
        audioManager.adjustSongVolume('song-instrumental', 2.5, 0.0);
        audioManager.adjustSongVolume('song-instrumental-full', 2.5, 0.0);
        audioManager.adjustSongVolume('song', 2.5, 0.14);
        audioManager.update();


        const masterTimeline = gsap.timeline();

        const sectionConfig = cameraConfig[2][deviceType];

        // Camera and Scene Animation
        const cameraTimeline = gsap.timeline({
            onStart: () => {
                gsap.to('.gridlines', { duration: 1.0, scaleY: 0.0, ease: 'power2.inOut' });
                gsap.to('.gridlines-quer', { duration: 1.0, scaleX: 0.0, ease: 'power2.inOut' });
                gsap.to('.gridlines', { duration: 1.0, delay: 2.2, scaleY: 1.0, ease: 'power2.inOut' });
                gsap.to('.gridlines-quer', { duration: 1.0, delay: 2.2, scaleX: 1.0, ease: 'power2.inOut' });
                cursor.removeText();
                cursor.removeState('-text');
                audioManager.playEffect('woosh-short', 3.5, 0.7);
                playSailOpenAnimations();
                playSpritzerAnimations();
                updateShipEmission(0.96);
                cameraFinalPosition.copy(camera.position);
                lerpedCameraPosition.copy(camera.position);
                isInitialAnimationComplete = false;
                lastCursorPosition.x = cursorPosition.x;
                lastCursorPosition.y = cursorPosition.y;
            },
            onComplete: () => {
                cursor.setText(`<img class="cursor-icon" src="./textures/ui/steuerrad.svg" width="20px" height="20px">« ${languageManager.getText('cursor_schiff_steuern')} »`);
                audioManager.playEffect('segel', 10.0, 0.25, true);
                updateInstructions(currentSection)
                cameraMoving = false;
                flyingPossibilities()
                playDolphinAnimation();
                playSegelWindAnimations();
            }
        });

        cameraTimeline.to(camera.position, {
            duration: 3.2,
            ease: 'power2.inOut',
            x: sectionConfig.position.x,
            y: sectionConfig.position.y,
            z: sectionConfig.position.z,
            onUpdate: () => {
                camera.lookAt(cameraTarget);
            }
        }, 0);

        cameraTimeline.to(cameraTarget, {
            duration: 3.2,
            ease: 'power2.inOut',
            x: sectionConfig.target.x,
            y: sectionConfig.target.y,
            z: sectionConfig.target.z,
            onUpdate: () => {
                camera.lookAt(cameraTarget);
            }
        }, 0);

        masterTimeline.add(cameraTimeline, 0);

        // Water and Material Animations
        const waterTimeline = gsap.timeline();

        waterTimeline
            .to(planeMaterial.uniforms.uLineVisibility, {
                value: 1,
                delay: 0.5,
                duration: 1.5,
                ease: 'power1.inOut',
                onUpdate: syncWaterUniforms
            }, 0)
            .to(planeMaterial.uniforms.uBorderVisibility, {
                value: 0,
                delay: 0.5,
                duration: 2.0,
                ease: 'power1.inOut',
                onUpdate: syncWaterUniforms
            }, 0)
            .to(planeMaterial.uniforms.uBorderSize, {
                value: 64.0,
                delay: 0.5,
                duration: 3.0,
                ease: 'power1.inOut',
                onUpdate: syncWaterUniforms
            }, 0)
            .to(planeMaterial.uniforms.uPortVisibility, {
                value: 0,
                delay: 2.0,
                duration: 1.5,
                ease: 'power1.inOut',
                onUpdate: syncWaterUniforms
            }, 0)
            .to(planeMaterial.uniforms.uWavesElevation, {
                value: 0.15,
                delay: 1.7,
                duration: 2.0,
                ease: 'power2.inOut',
                onUpdate: syncWaterUniforms
            }, 0);

        masterTimeline.add(waterTimeline, 0);

        function syncWaterUniforms() {
            planes.forEach(plane => {
                plane.material.uniforms.uLineVisibility.value = planeMaterial.uniforms.uLineVisibility.value;
                plane.material.uniforms.uBorderVisibility.value = planeMaterial.uniforms.uBorderVisibility.value;
                plane.material.uniforms.uBorderSize.value = planeMaterial.uniforms.uBorderSize.value;
                plane.material.uniforms.uPortVisibility.value = planeMaterial.uniforms.uPortVisibility.value;
                plane.material.uniforms.uWavesElevation.value = planeMaterial.uniforms.uWavesElevation.value;
            });
        }

        // Environment Animations
        const environmentTimeline = gsap.timeline();

        environmentTimeline
            .to(godrayMaterial.uniforms.uIntensity, {
                value: 0.0,
                delay: 0,
                duration: 1.0,
                ease: 'power1.inOut'
            }, 0)
            .to(sunPlaneMaterial.uniforms.uSunAlpha, {
                value: 1.0,
                delay: 1.7,
                duration: 1.5,
                ease: 'power2.inOut'
            }, 0)
            .to(shinePlaneMaterial.uniforms.uShineAlpha, {
                value: 0.0,
                delay: 0.3,
                duration: 1.5,
                ease: 'power2.inOut',
                onComplete: removeShineFromScene
            }, 0);

        masterTimeline.add(environmentTimeline, 0);

        // Ship and Seagull Animations
        const shipTimeline = gsap.timeline();

        shipTimeline
            .to(shipPositioner.rotation, {
                delay: 0,
                duration: 3.0,
                ease: 'power1.inOut',
                y: Math.PI * 1.2
            }, 0)
            .to(neigungsKorrektur, {
                duration: 2.0,
                ease: 'linear',
                value: 0.07
            }, 0)
            .to([seagull.rotation, seagull2.rotation, seagull3.rotation], {
                duration: 1.5,
                ease: 'power1.inOut',
                y: (i) => Math.PI * 0.25 - [seagullPositioner.rotation.y, seagullPositioner2.rotation.y, seagullPositioner3.rotation.y][i]
            }, 0);

        masterTimeline.add(shipTimeline, 0);

        // Island Animations
        const islandTimeline = gsap.timeline();

        islandTimeline
            .to(islandRock.position, {
                duration: 3.5,
                ease: 'power2.inOut',
                y: -1.5
            }, 0)
            .to(islandHouse.position, {
                duration: 2.5,
                ease: 'power2.inOut',
                y: -1.5,
            }, 0)
            .to(pointLight1, {
                duration: 2.5,
                ease: 'linear',
                intensity:0,
            }, 0)
            .to(pointLight2, {
                duration: 2.5,
                ease: 'linear',
                intensity:0,
            }, 0)
            .to(pointLight3, {
                duration: 2.5,
                ease: 'linear',
                intensity:0,
            }, 0);



        masterTimeline.add(islandTimeline, 0);
    }

    // Section 3

    if (currentSection === 3 && !cameraMoving) {
        cameraMoving = true;
        //addIslandToScene();
        addShineToScene();
        flyingPossibilitiesStop()

        // Section Counter Animation
        updateSectionCounter(currentSection);
        updateSectionText(currentSection);
        document.querySelector('.instruction-02').classList.remove('visible');
        document.querySelector('.footer-bg.section-5').classList.remove('visible');

        // Audio adjustments
        audioManager.adjustBackgroundVolume(6.0, 1.0);
        audioManager.playBackgroundForSection('ship-calm', 1.0, 1.1);
        audioManager.adjustSongVolume('song-instrumental-full', 3.0, 0.20);
        audioManager.adjustSongVolume('song', 3.0, 0.0);
        audioManager.stopEffect('segel');
        audioManager.update();

        const masterTimeline = gsap.timeline();

        const sectionConfig = cameraConfig[3][deviceType];

        // Camera and Scene Animation
        const cameraTimeline = gsap.timeline({
            onStart: () => {
                gsap.to('.gridlines', { duration: 1.0, scaleY: 0.0, ease: 'power2.inOut' });
                gsap.to('.gridlines-quer', { duration: 1.0, scaleX: 0.0, ease: 'power2.inOut' });
                gsap.to('.gridlines', { duration: 1.0, delay: 2.0, scaleY: 1.0, ease: 'power2.inOut' });
                gsap.to('.gridlines-quer', { duration: 1.0, delay: 2.0, scaleX: 1.0, ease: 'power2.inOut' });
                cursor.removeText();
                cursor.removeState('-text');
                audioManager.playEffect('woosh-long', 4.0, 0.7);
                stopSegelWindAnimations();
                playSailOpenAnimationsReverse();
                updateShipEmission(1.005);
            },
            onComplete: () => {
                cursor.setText(`<img class="cursor-icon" src="./textures/ui/kamera-bewegen.svg" width="20px" height="20px">${languageManager.getText('cursor_kamera_bewegen')}`);
                document.querySelector('.scroll-icon.down').classList.add('visible');
                stopSpritzerAnimations();{
                cameraFinalPosition.copy(camera.position);
                lerpedCameraPosition.copy(camera.position);
                isInitialAnimationComplete = true;
                lastCursorPosition.x = cursorPosition.x;
                lastCursorPosition.y = cursorPosition.y;
                cameraMoving = false;
                }
            }
        });

        cameraTimeline.to(camera.position, {
            duration: 3.0,
            ease: 'power2.inOut',
            x: sectionConfig.position.x,
            y: sectionConfig.position.y,
            z: sectionConfig.position.z,
            onUpdate: () => {
                camera.lookAt(cameraTarget);
            }
        }, 0);

        cameraTimeline.to(cameraTarget, {
            duration: 3.0,
            ease: 'power2.inOut',
            x: sectionConfig.target.x,
            y: sectionConfig.target.y,
            z: sectionConfig.target.z,
            onUpdate: () => {
                camera.lookAt(cameraTarget);
            }
        }, 0);

        masterTimeline.add(cameraTimeline, 0);

        // Water and Material Animations
        const waterTimeline = gsap.timeline();

        waterTimeline
            .to(planeMaterial.uniforms.uLineVisibility, {
                value: 0,
                delay: 0.5,
                duration: 1.5,
                ease: 'power2.inOut',
                onUpdate: syncWaterUniforms
            }, 0)
            .to(planeMaterial.uniforms.uPortVisibility, {
                value: 1,
                delay: 0,
                duration: 1.5,
                ease: 'power1.inOut',
                onUpdate: syncWaterUniforms
            }, 0)
            .to(planeMaterial.uniforms.uAlpha, {
                value: 1.0,
                duration: 1.5,
                ease: 'power1.inOut',
                onUpdate: syncWaterUniforms,
                onComplete: () => {
                    if (lastSection === 2) {
                    audioManager.playEffect('bell-ring', 2.75, 0.08);
                    }
                }
            }, 0)
            .to(planeMaterial.uniforms.uWaterNoiseVisibility, {
                value: 0.05,
                duration: 1.5,
                ease: 'power1.inOut',
                onUpdate: syncWaterUniforms
            }, 0)
            .to(planeMaterial.uniforms.uWavesElevation, {
                delay: 1.0,
                value: 0.05,
                duration: 2.0,
                ease: 'power2.inOut',
                onUpdate: syncWaterUniforms
            }, 0);

        masterTimeline.add(waterTimeline, 0);

        function syncWaterUniforms() {
            planes.forEach(plane => {
                plane.material.uniforms.uLineVisibility.value = planeMaterial.uniforms.uLineVisibility.value;
                plane.material.uniforms.uPortVisibility.value = planeMaterial.uniforms.uPortVisibility.value;
                plane.material.uniforms.uAlpha.value = planeMaterial.uniforms.uAlpha.value;
                plane.material.uniforms.uWaterNoiseVisibility.value = planeMaterial.uniforms.uWaterNoiseVisibility.value;
                plane.material.uniforms.uWavesElevation.value = planeMaterial.uniforms.uWavesElevation.value;
            });
        }

        // Environment Animations
        const environmentTimeline = gsap.timeline();

        environmentTimeline
            .to(sunPlaneMaterial.uniforms.uSunAlpha, {
                value: 0.0,
                delay: 0.3,
                duration: 1.5,
                ease: 'power2.inOut',
                onComplete: removeSunFromScene
            }, 0)
            .to(shinePlaneMaterial.uniforms.uShineAlpha, {
                value: 1.0,
                delay: 1.7,
                duration: 1.5,
                ease: 'power2.inOut'
            }, 0);

        masterTimeline.add(environmentTimeline, 0);

        // Ship and Island Animations
        const shipTimeline = gsap.timeline();

        shipTimeline
            .to(shipPositioner.rotation, {
                delay: 1.0,
                duration: 3.0,
                ease: 'power1.inOut',
                y: Math.PI * 1.0
            }, 0)
            .to(neigungsKorrektur, {
                duration: 2.0,
                ease: 'linear',
                value: -0.04
            }, 0)
            .to(islandRock.position, {
                duration: 2.5,
                ease: 'power2.inOut',
                y: -0.42
            }, 0)
            .to(islandHouse.position, {
                duration: 3.5,
                ease: 'power2.inOut',
                y: -0.40
            }, 0)
            .to(pointLight1, {
                duration: 2.5,
                ease: 'linear',
                intensity:intensityValue,
            }, 0)
            .to(pointLight2, {
                duration: 2.5,
                ease: 'linear',
                intensity:intensityValue,
            }, 0)
            .to(pointLight3, {
                duration: 2.5,
                ease: 'linear',
                intensity:intensityValue,
            }, 0)
            .to([seagull.rotation, seagull2.rotation, seagull3.rotation], {
                duration: 1.5,
                ease: 'power1.inOut',
                y: 0
            }, 0);

            

        masterTimeline.add(shipTimeline, 0);
    }

    if (currentSection === 4 && !cameraMoving) {
        cameraMoving = true;

        // Section Counter Animation
        updateSectionCounter(currentSection);
        updateSectionText(currentSection);
        document.querySelector('.footer-bg.section-5').classList.add('visible');
        document.querySelector('.scroll-icon.down').classList.remove('visible');

        const masterTimeline = gsap.timeline();

        const sectionConfig = cameraConfig[4][deviceType];

        // Camera and Scene Animation
        const cameraTimeline = gsap.timeline({
            onStart: () => {
                gsap.to('.gridlines', { duration: 1.0, scaleY: 0.0, ease: 'power2.inOut' });
                gsap.to('.gridlines-quer', { duration: 1.0, scaleX: 0.0, ease: 'power2.inOut' });
                gsap.to('.gridlines', { duration: 1.0, delay: 2.5, scaleY: 1.0, ease: 'power2.inOut' });
                gsap.to('.gridlines-quer', { duration: 1.0, delay: 2.5, scaleX: 1.0, ease: 'power2.inOut' });
                cursor.removeText();
                cursor.removeState('-text');
                audioManager.playEffect('woosh-long', 6.0, 0.65);
                audioManager.adjustBackgroundVolume(6.0, 0.2);
                audioManager.adjustSongVolume('song-instrumental-full', 2.0, 0.30);
                cameraFinalPosition.copy(camera.position);
                lerpedCameraPosition.copy(camera.position);
                isInitialAnimationComplete = false;
                lastCursorPosition.x = cursorPosition.x;
                lastCursorPosition.y = cursorPosition.y;
            },
            onComplete: () => {
                cameraMoving = false;
            }
        });

        cameraTimeline.to(camera.position, {
            duration: 4.2,
            ease: 'power3.inOut',
            x: sectionConfig.position.x,
            y: sectionConfig.position.y,
            z: sectionConfig.position.z,
            onUpdate: () => {
                camera.lookAt(cameraTarget);
            }
        }, 0);

        cameraTimeline.to(cameraTarget, {
            duration: 4.2,
            ease: 'power3.inOut',
            x: sectionConfig.target.x,
            y: sectionConfig.target.y,
            z: sectionConfig.target.z,
            onUpdate: () => {
                camera.lookAt(cameraTarget);
            }
        }, 0);

        masterTimeline.add(cameraTimeline, 0);

        // Environment Animations
        const environmentTimeline = gsap.timeline();

        environmentTimeline.to(shinePlaneMaterial.uniforms.uShineAlpha, {
            value: 0.0,
            duration: 1.0,
            ease: 'power2.inOut',
            onComplete: removeShineFromScene
        }, 0);

        masterTimeline.add(environmentTimeline, 0);
    }}

window.addEventListener('wheel', onScroll);
window.addEventListener('touchstart', onTouchStart);
window.addEventListener('touchmove', onTouchMove);

/*
window.addEventListener('DOMContentLoaded', (event) => {
    window.scrollTo(0, 0);
});*/


/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0;

let currentShipMovement = { x: 0, y: 0 };
let neigungsKorrektur = { value: 0.07 };
const seagullPeriod = 9.166666666666667;
const seagullOmega = (2 * Math.PI) / seagullPeriod;
const seagullPhaseShift1 = Math.PI * 1.5;
const seagullPhaseShift2 = Math.PI * 1.5 - 2;
const seagullPhaseShift3 = Math.PI * 1.5 - 4;

const tick = () => {
    const elapsedTime = clock.getElapsedTime();
    const deltaTime = elapsedTime - previousTime;
    previousTime = elapsedTime;

 
    // Wave
    const waveElevation = (planeMaterial.uniforms.uWavesElevation.value / 3) * 20;
    const waveFrequency = (planeMaterial.uniforms.uWavesFrequency.value.x);
    const waveSpeed = 1.2;

    // Aktualisiere die uTime-Uniform für alle Planes in einer Schleife
    for (let i = 0; i < planes.length; i++) {
        planes[i].material.uniforms.uTime.value = elapsedTime;
    }

    // Aktualisiere die Y-Position jedes Würfels im Array `cubes`
    indexCube = 0; // Index vor der Schleife zurücksetzen

    for (let i = 0; i < gridSize; i++) {
        for (let j = 0; j < gridSize; j++) {
            // Position des Würfels berechnen
            const x = (cubeSize + gap) * i - offsetCube;
            const z = (cubeSize + gap) * j - offsetCube;

            // Wellenberechnung
            const wave = Math.sin(elapsedTime * waveSpeed + (x * 0.5 + z) * waveFrequency) * waveElevation + waveElevation;

            // Skalierung und Position setzen
            dummy.position.set(x, (wave - 1) * cubeSize / 2, z);
            dummy.scale.set(1, wave, 1);
            dummy.updateMatrix();

            // Matrix für die Instanz aktualisieren
            instancedCubeMesh.setMatrixAt(indexCube, dummy.matrix);

            indexCube++;
        }
    }

    // Informieren Sie Three.js, dass die Instanzen aktualisiert wurden
    instancedCubeMesh.instanceMatrix.needsUpdate = true;
    
    if (ship) {
        const shipGridLocationX = 22;
        const shipGridLocationZ = 22;

        // Berechnung der Indizes der gewünschten Cubes
        const index1 = shipGridLocationX * gridSize + shipGridLocationZ;
        const index2 = (shipGridLocationX - 5) * gridSize + (shipGridLocationZ - 4);

        // Überprüfen Sie, ob die Indizes innerhalb der gültigen Bereiche liegen
        function isValidIndex(index, count) {
            return index >= 0 && index < count;
        }

        if (isValidIndex(index1, count) && isValidIndex(index2, count)) {
            // Abrufen der Transformationsdaten der Cubes
            const { position: pos1, rotation: rot1 } = getInstanceTransform(instancedCubeMesh, index1);
            const { position: pos2, rotation: rot2 } = getInstanceTransform(instancedCubeMesh, index2);
            
            // Aktualisieren der shipPositioner-Position und -Rotation basierend auf den Cubes
            shipPositioner.position.y = (pos1.y * 2) + 0.06;
            shipPositioner.rotation.x = ((pos2.y / 2) * 7.1) - neigungsKorrektur.value;
        } else {
            console.warn('Einer der berechneten Indizes ist außerhalb des gültigen Bereichs.');
        }
        
        if (currentSection < 1) {
            shipPositioner.rotation.y -= deltaTime * 0.2;
            
            if (shipPositioner.rotation.y > Math.PI * 2.2) {
                shipPositioner.rotation.y = 2.2 * Math.PI;
            } else if (shipPositioner.rotation.y < Math.PI * 0.2) {
                shipPositioner.rotation.y = 2.2 * Math.PI;
            }
        }
    
        if (currentSection === 2 && cameraMoving === false) {
            let targetX, targetRotationY;

            if (isMobile) {
                const maxGamma = 25; // Maximale Neigung in Grad
                const clampedGamma = Math.max(-maxGamma, Math.min(maxGamma, deviceOrientation.gamma));
                targetX = (clampedGamma / maxGamma) * 0.4; // Normalisieren zwischen -0.5 & 0.5
                targetRotationY = (targetX * (Math.PI * 0.5)) + Math.PI * 1.2;
            } else {
                // Nutzung der Cursorposition zur Steuerung
                targetX = cursorPosition.x;
                targetRotationY = (cursorPosition.x * (Math.PI * 0.5)) + Math.PI * 1.2;
            }

            const rotationDelta = (targetRotationY - shipPositioner.rotation.y) * 0.03;
            shipPositioner.rotation.y += rotationDelta;

            // Berechnen Sie die Bewegung basierend auf der Rotation
            currentShipMovement.x += (targetX - currentShipMovement.x) * 0.02;

            // Aktualisieren Sie den Uniform
            instancedMesh.material.uniforms.uShipMovement.value.set(currentShipMovement.x, 0);
        }

        if (shipMixer) {
            shipMixer.update(deltaTime);
        }
    }

    if ((dolphin)) {
        if (dolphinMixer) {
            dolphinMixer.update(deltaTime);
        }
    }

    if (seagull) {
        if (currentSection !== 2) {
            seagullPositioner.rotation.y -= deltaTime * 0.40;
            // Setze rotation.y zurück, wenn es die Grenzen überschreitet
            if (seagullPositioner.rotation.y > Math.PI) {
                seagullPositioner.rotation.y -= 2 * Math.PI;
            } else if (seagullPositioner.rotation.y < -Math.PI) {
                seagullPositioner.rotation.y += 2 * Math.PI;
            }
            seagullPositioner.position.y = 1.2 + Math.sin(seagullOmega * elapsedTime + seagullPhaseShift1) * 0.08;
        } else {
            seagullPositioner.position.y = 1.2 + Math.sin(seagullOmega * elapsedTime + seagullPhaseShift1) * 0.08;
            const targetRotation = shipPositioner.rotation.y - seagullPositioner.rotation.y - (Math.PI * 0.95)
            const lerpFactor = 0.04;
            seagull.rotation.y = THREE.MathUtils.lerp(seagull.rotation.y, targetRotation, lerpFactor);
            //seagull.rotation.x = Math.PI * cursorPosition.x * 0.22
            //seagull.rotation.z = Math.PI * cursorPosition.x * 0.15
        }
    
        if (seagullMixer) {
            seagullMixer.update(deltaTime);
        }
    }

    if (seagull2) {
        if (currentSection !== 2) {
            seagullPositioner2.rotation.y += deltaTime * 0.30;
            // Setze rotation.y zurück, wenn es die Grenzen überschreitet
            if (seagullPositioner2.rotation.y > Math.PI) {
                seagullPositioner2.rotation.y = - Math.PI;
            } else if (seagullPositioner2.rotation.y < -Math.PI) {
                seagullPositioner2.rotation.y = Math.PI;
            }
            seagullPositioner2.position.y = 1.0 + Math.sin(seagullOmega * elapsedTime + seagullPhaseShift2) * 0.08;
        } else {
            seagullPositioner2.position.y = 1.0 + Math.sin(seagullOmega * elapsedTime + seagullPhaseShift2) * 0.08;
            const targetRotation = shipPositioner.rotation.y - seagullPositioner2.rotation.y - (Math.PI * 0.95)
            const lerpFactor = 0.04;
            seagull2.rotation.y = THREE.MathUtils.lerp(seagull2.rotation.y, targetRotation, lerpFactor);
        }
    
        if (seagullMixer2) {
            seagullMixer2.update(deltaTime);
        }
    }

    if (seagull3) {
        if (currentSection !== 2) {
            seagullPositioner3.rotation.y += deltaTime * 0.50;
            if (seagullPositioner3.rotation.y > Math.PI) {
                seagullPositioner3.rotation.y = - Math.PI;
            } else if (seagullPositioner3.rotation.y < -Math.PI) {
                seagullPositioner3.rotation.y = Math.PI;
            }
            seagullPositioner3.position.y = 0.8 + Math.sin(seagullOmega * elapsedTime + seagullPhaseShift3) * 0.08;
        } else {
            seagullPositioner3.position.y = 0.8 + Math.sin(seagullOmega * elapsedTime + seagullPhaseShift3) * 0.08;
            const targetRotation = shipPositioner.rotation.y - seagullPositioner3.rotation.y - (Math.PI * 0.95)
            const lerpFactor = 0.04;
            seagull3.rotation.y = THREE.MathUtils.lerp(seagull3.rotation.y, targetRotation, lerpFactor);
        }
    
        if (seagullMixer3) {
            seagullMixer3.update(deltaTime);
        }
    }

     if (islandHouse && currentSection !== 1 || currentSection !== 2 ) {
        if (islandMixer) {
            islandMixer.update(deltaTime);
        }
    }

    if (currentSection === 3) {
    let progress1 = (elapsedTime % duration1) / duration1;
    let progress2 = (elapsedTime % duration2) / duration2;
    let progress3 = (progress1 + 0.5) % 1;
    
    // Interpolieren Sie die Position für jedes Licht
    pointLight1.position.lerpVectors(startPos1, endPos1, progress1);
    pointLight2.position.lerpVectors(startPos2, endPos2, progress2);
    pointLight3.position.lerpVectors(startPos1, endPos1, progress3);
    }

    // Kamerasteuerung Sektion 1
    if (currentSection === 1 && !isMobile && isInitialAnimationComplete) {
        const maxOffset = 720; // Maximale Verschiebung der Kamera
        const sensitivity = 0.006; // Reduzierte Empfindlichkeit für mobile Geräte
        const lerpFactor = 0.05; // LERP-Faktor für sanfte Bewegung

        let targetX, targetY;

        // Nutzung der Cursorposition zur Steuerung
        targetX = cameraFinalPosition.x + cursorPosition.x * maxOffset * sensitivity;
        targetY = cameraFinalPosition.y + cursorPosition.y * maxOffset * sensitivity;

        // LERP anwenden, um sanft zur Zielposition zu gelangen
        lerpedCameraPosition.x = THREE.MathUtils.lerp(lerpedCameraPosition.x, targetX, lerpFactor);
        lerpedCameraPosition.y = THREE.MathUtils.lerp(lerpedCameraPosition.y, targetY, lerpFactor);

        // Kameraposition aktualisieren
        camera.position.x = lerpedCameraPosition.x;
        camera.position.y = lerpedCameraPosition.y;

        // Kamera auf das Ziel ausrichten
        camera.lookAt(cameraTarget);
    }

    // Kamerasteuerung Sektion 3
    if (currentSection === 3 && isInitialAnimationComplete) {
        const maxOffset = isMobile ? 480 : 720; // Maximale Verschiebung der Kamera
        const sensitivity = isMobile ? 0.02 : 0.006; // Reduzierte Empfindlichkeit für mobile Geräte
        const lerpFactor = isMobile ? 0.05 : 0.05; // LERP-Faktor für sanfte Bewegung

        let targetX, targetY;

        if (isMobile) {
            // Nutzung der Gyrosensor-Daten zur Steuerung
            const maxGamma = 25; // Maximale Neigung in Grad
            const clampedGamma = Math.max(-maxGamma, Math.min(maxGamma, deviceOrientation.gamma));
            const normalizedGamma = clampedGamma / maxGamma; // Normalisieren zwischen -1 und 1

            targetX = cameraFinalPosition.x + normalizedGamma * maxOffset * sensitivity;
            
            targetY = cameraFinalPosition.y;
        } else {
            targetX = cameraFinalPosition.x + cursorPosition.x * maxOffset * sensitivity;
            targetY = cameraFinalPosition.y + cursorPosition.y * maxOffset * sensitivity;
        }

        lerpedCameraPosition.x = THREE.MathUtils.lerp(lerpedCameraPosition.x, targetX, lerpFactor);
        lerpedCameraPosition.y = THREE.MathUtils.lerp(lerpedCameraPosition.y, targetY, lerpFactor);

        camera.position.x = lerpedCameraPosition.x;
        camera.position.y = lerpedCameraPosition.y;

        camera.lookAt(cameraTarget);
    }

    // Logo Farbe anpassen
    if (currentSection >= 4 && transitionProgress < 1) {
        transitionProgress += deltaTime * 0.30;
        transitionProgress = Math.min(transitionProgress, 1);
        waterCubeMaterial.color.lerpColors(mainColor, bbdColor, transitionProgress);
    }

    // Logo Farbe rückwärts anpassen, wenn Sektion 7 verlassen wird
    if (currentSection < 4 && transitionProgress > 0) {
        transitionProgress -= deltaTime *0.30;
        transitionProgress = Math.max(transitionProgress, 0);
        waterCubeMaterial.color.lerpColors(mainColor, bbdColor, transitionProgress);
    }
    
    // Update
    planeMaterial.uniforms.uTime.value = elapsedTime
    
    if (currentSection === 0) {
    cloudPlaneMaterial.uniforms.uTime.value = elapsedTime
    }
    
    if (currentSection === 1) {
    godrayMaterial.uniforms.uTime.value = elapsedTime
    }

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}